(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('BalancesMvcController', BalancesMvcController);

    BalancesMvcController.$inject = ['$scope', '$state', 'entity', 'defaultPageSize', '$translate'];

    function BalancesMvcController ($scope, $state, entity, defaultPageSize, $translate) {
        var vm = this;
        console.log("MVC Balances Controller started");
        if(entity.hasOwnProperty('mvc')) { 
	        vm.mvcBalancesDataSource = new kendo.data.DataSource({
	    		schema: {
				      model: {
	//			        id: "token",
				        fields: {
	//			          "token": { type: "string"},
				          "bal_dtime": { type: "date" },
				        }
				      }
	      		},
	  			data: entity.mvc[0].balances,
	  			pageSize: defaultPageSize,
	  			sort: {
	  				field: "bal_dtime",
	  				dir: "desc"
	  			}
	        });
	        
	        vm.mvcBalancesOptions = {
	        		scrollable: true,
	                sortable: true,
	                filterable: {
	                    extra: false,
	                    operators: {
	                        string: {
	                            startswith: "Starts with",
	                            eq: "Is equal to",
	                            neq: "Is not equal to"
	                        }
	                    }
	                },
	                pageable: {
	                	messages: {
	                	      empty: "No data"
	                	}
	                },
	                toolbar: [
	                          { 
			                    template: "<span style='line-height:22px;' translate='modules.mvc.titles.balances'></span>"
			                    		 + "<button style='margin-right:1em;' class='pull-right btn btn-default btn-xs k-grid-excel'>"
			                    		 + "<span class='fa fa-file-excel-o'></span>"
			                             + "&nbsp;<span translate='modules.mvc.excel'>"
			                             + "</span></button>"
	                          }
	                ],
	                excel: {
	                	fileName: "mvc-balances.xlsx",
	                    allPages: true
	                },
	                excelExport: function(e) {
	                	e.workbook.sheets[0].columns.forEach(function(column){
	                		column.autoWidth = true;
	                	});
//	                	e.workbook.sheets[0].columns = [
//	                		{ width:100 },
//	                		{ width:300 },
//	                		{ width:400 },
//	                		// ...
//	                	];
	                    e.workbook.sheets[0].rows.forEach(function(row){
	                      if(row.type != 'data'){
	                      	row.cells.forEach(function(cell){
	                          cell.value = $translate.instant(cell.value);
	                        });
	                      }
	                	});
	                },
	                columns: [{
	                    	field: "bal_dtime",
	                    	title: "{{'modules.mvc.table.bal_date'|translate}}",
	                        format      : "{0:G}",
	                        parseFormats: ["yyyy-MM-dd'T'HH:mm:ss.zzz"],
		                    width: "15ch",
		                    filterable: {
		                    	ui: "datepicker"
		                    }
	                    },{
	                        field: "amt_avl",
	                        title: "{{'modules.mvc.table.amt_avl'|translate}}",
	                        width: "15ch",
	                        format: "{0:n2}",
	                        attributes: {class:"text-right"},
	                        filterable: false
	                    },{
	                        field: "blk_amt",
	                        title: "{{'modules.mvc.table.blk_amt'|translate}}",
	                        width: "15ch",
	                        format: "{0:n2}",
	                        attributes: {class:"text-right"},
	                        filterable: false
	                    },{
	                        field: "cred_limit",
	                        title: "{{'modules.mvc.table.cred_limit'|translate}}",
	                        width: "15ch",
	                        format: "{0:n2}",
	                        attributes: {class:"text-right"},
	                        filterable: false
	                    },{
	                        field: "fin_amt",
	                        title: "{{'modules.mvc.table.fin_amt'|translate}}",
	                        width: "15ch",
	                        format: "{0:n2}",
	                        attributes: {class:"text-right"},
	                        filterable: false
	                    }]
	 
	        };
        }
    }
})();
