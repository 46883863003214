(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        	.state('modules.bpm', {
				url : '/bpm',
				parent : 'modules',
				abstract: true,
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO'], //ROLE_ADMIN
	            },
				views : {
					'content@' : {
						templateUrl : 'app/modules/bpm/bpm.template.html',
						controller : 'BpmMenuController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }]
				}
			})
	       	.state('modules.bpm.dashboard', {
				url : '/dashboard',
				parent : 'modules.bpm',
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO'], //ROLE_ADMIN
	            },
				views : {
					'bpm-body': {
						templateUrl : 'app/modules/bpm/dashboard/dashboard.bpm.template.html',
						controller : 'DashboardBpmController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/bpm');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
					previousState: ["$state", function ($state) {
	                    var currentStateData = {
	                        name: $state.current.name || 'modules.bpm',
	                        params: $state.params,
	                        url: $state.href($state.current.name, $state.params)
	                    };
	                    return currentStateData;
	                }],
	                stat: ['Bpm', function(Bpm) {
	                    return Bpm.getStat().$promise;
	                }]
	                
				}
			})
			.state('modules.bpm.tasks', {
				url : '/tasks',
				parent : 'modules.bpm',
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
	            },
				views : {
					'bpm-body' : {
						templateUrl : 'app/modules/bpm/tasks/tasks.bpm.template.html',
						controller : 'TasksBpmController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/bpm');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
					previousState: ["$state", function ($state) {
	                    var currentStateData = {
	                        name: $state.current.name || 'modules.bpm',
	                        params: $state.params,
	                        url: $state.href($state.current.name, $state.params)
	                    };
	                    return currentStateData;
	                }]
				}
			})
	       	.state('modules.bpm.tasks.detail', {
				url : '/:taskId',
				parent : 'modules.bpm.tasks',
				params: {tasks:null},
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
	            },
				views : {
					'bpm-sub-header@modules.bpm' : {
						templateUrl : 'app/modules/bpm/tasks/tasks.bpm.template.html',
						controller : 'TasksBpmController',
						controllerAs : 'vm'
					},
					'bpm-body@modules.bpm' : {
						templateUrl : 'app/modules/bpm/tasks/detail.task.bpm.template.html',
						controller : 'DetailTaskBpmController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/bpm');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
					previousState: ["$state", function ($state) {
	                    var currentStateData = {
	                        name: $state.current.name || 'modules.bpm',
	                        params: $state.params,
	                        url: $state.href($state.current.name, $state.params)
	                    };
	                    return currentStateData;
	                }],
	                detailMode: function(){
	                	return {
	                		edit: false,
	                		create: false
	                	};
	                }
				}
			})
			.state('modules.bpm.tasks.detail.edit', {
				url : '/edit',
				parent : 'modules.bpm.tasks.detail',
				params: {currentTask: null},
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
	            },
				views : {
					'bpm-sub-header@modules.bpm' : {
						templateUrl : 'app/modules/bpm/tasks/tasks.bpm.template.html',
						controller : 'TasksBpmController',
						controllerAs : 'vm'
					},
					'bpm-body@modules.bpm' : {
						templateUrl : 'app/modules/bpm/tasks/detail.task.bpm.template.html',
						controller : 'DetailTaskBpmController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/bpm');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
					previousState: ["$state", function ($state) {
	                    var currentStateData = {
	                        name: $state.current.name || 'modules.bpm',
	                        params: $state.params,
	                        url: $state.href($state.current.name, $state.params)
	                    };
	                    return currentStateData;
	                }],
	                detailMode: function(){
	                	return {
	                		edit: true,
	                		create: false
	                	};
	                }
				}
			})
			.state('modules.bpm.tasks.detail.delete', {
				url : '/delete',
				params: {currentTask: null},
				parent : 'modules.bpm.tasks.detail',
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
	            },
	            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
	                $uibModal.open({
	                    templateUrl: 'app/modules/bpm/tasks/delete.task.bpm.template.html',
	                    controller: 'DeleteTaskBpmController',
	                    controllerAs: 'vm',
	                    size: 'md'
	                }).result.then(function() {
	                    $state.go('modules.bpm.tasks', null, { reload: true });
	                }, function() {
	                    $state.go('modules.bpm.tasks.detail', {taskId: $state.params.taskId});
	                });
	            }]
	        })
			;
    }
})();
