(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('ConditionsLoanController', ConditionsLoanController);

    ConditionsLoanController.$inject = ['$scope', '$state', 'menuWithHeader', 'Rights'];

    function ConditionsLoanController ($scope, $state, menuWithHeader, Rights) {
        var vm = this;
        console.log("Loan Conditions Controller started");
        
        vm.loan = menuWithHeader[0];
    }
})();
