(function() {
	'use strict';
	angular.module('sigmaApp').factory('History', History);

	History.$inject = [ '$resource', 'baseUrl', 'DateUtils'];

	function History($resource, baseUrl, DateUtils) {
		
		var resourceUrl = baseUrl + 'api/lapi/events/:docId';

		return $resource(resourceUrl,{},
				{
					'get' : {
						method : 'GET',
						isArray : true,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					}
				
				});
	}
})();