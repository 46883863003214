(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$state', 'Auth', 'Principal', 'ProfileService', '$rootScope', '$scope', 'formHelper', '$element', '$window'];

    function NavbarController ($state, Auth, Principal, ProfileService, $rootScope, $scope, formHelper, $element, $window) {
        var vm = this;

        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;
        
        
        ProfileService.getProfileInfo().then(function(response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

        vm.account = null;
        vm.showSearch = false;
        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.search = search;
        vm.searchSubmit = searchSubmit;
        
        vm.$state = $state;
        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });
        getAccount();
        
        if(vm.account === null) formHelper.setFocus("username");
        
        function search() {
        	collapseNavbar();
        	vm.showSearch = vm.showSearch ?  false : true;
        	formHelper.setFocus("siteSearch");
        	if(vm.showSearch) resizeSearch();
        }

        angular.element($window).bind('resize', function(){
            $scope.$apply(function() {
            	if(vm.showSearch) resizeSearch();
            });
        });
        
        function resizeSearch() {
        	var searchWidth = $element.find('div')[0].getBoundingClientRect().width - $element.find('ul')[3].getBoundingClientRect().width - 30; 
    		vm.searchWidth = searchWidth;
        }
        
        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
        
        function searchSubmit() {
        	collapseNavbar();
        	console.log("start search ...");
        	var searchString = vm.searchValue.trim().replace(" ",","); 
        	console.log(searchString);
        	vm.showSearch = false;
        	vm.searchValue = null;
        	$state.go('modules.search',{ look4: searchString });
        }
        function login (event) {
        	collapseNavbar();
            event.preventDefault();
            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe
            }).then(function () {
                vm.authenticationError = false;
                if ($state.current.name === 'register' || $state.current.name === 'activate' ||
                    $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                    $state.go('home');
                }
                
                $rootScope.$broadcast('authenticationSuccess');

                // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                // since login is succesful, go to stored previousState and clear previousState
                if (Auth.getPreviousState()) {
                    var previousState = Auth.getPreviousState();
                    Auth.resetPreviousState();
                    $state.go(previousState.name, previousState.params);
                }
            }).catch(function () {
                vm.authenticationError = true;
            });
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            vm.showSearch = false;
            $state.go('home');
            formHelper.setFocus("username");
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }
    }
})();
