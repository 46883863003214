(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('ApplicationsController', ApplicationsController);

    ApplicationsController.$inject = ['$rootScope', '$scope', '$state', 'defaultPageSize', 'Application', '$q', '$interval', 'shortRefresh', 'longRefresh'];

    function ApplicationsController ($rootScope, $scope, $state, defaultPageSize, Application, $q, $interval, shortRefresh, longRefresh) {
        var vm = this;
        vm.refresh = longRefresh;
                
        console.log("application list controller");
        
        vm.mainData = new kendo.data.DataSource({
        	schema: {
			      model: {
			        id: "id",
			        fields: {
			          "id": { type: "number", validation:{required:true} },
			          "applDate": { type: "date" },
			          "personCode": { type: "string" },
			          "firstName": { type: "string" },
			          "lastName": { type: "string" },
			          "fullName": { type: "string"},
			          "applSum": { type: "number" },
			          "applStatus": { type: "string" },
			          "checkResult": { type: "string" },
			          "isEditable": { type: "boolean" },
			          "canCreateLoan": { type: "boolean" },
			          "updated": { type: "date" }
			    	}
				},
                parse: function(d){
                	$.each(d, function (idx, elem) {
                        elem.fullName = elem.firstName + " " + elem.lastName;
                    });
                	return d;
                }
			},
			transport: {
				read: function(e){
					
					Application.query(
							function(response){
								e.success(response);
							},
							function(error){
								console.log('application list error');
								e.error(error);
							}
					);
				}
			},
			pageSize: defaultPageSize,
			sort: {
				field: "updated",
				dir: "desc"
			}
        });
        
        vm.mainOptions = {
        		scrollable: true,
                sortable: true,                
                pageable: {
                	refresh: true,
                	messages: {
                	      empty: "No data"
                	    }
                },
                filterable: {
                    extra: false,
                    operators: {
                        string: {
                            contains: "Contains",
                        }
                    }
                },
                toolbar: [
                          {	
                        	name: "excel", 
                        	text: "Excel"
                          }],
                excel: {
                    fileName: "applications.xlsx"
                },
                columns: [{
	                    field: "applDate",
	                    headerTemplate: "<span translate='modules.application.list.applDate'>",
	                    format: "{0:d}",
	                    width: "14ch",
	                    filterable: {
	                    	ui: "datepicker"
	                    }
                    },{
	                    field: "personCode",
	                    headerTemplate: "<span translate='modules.application.list.personCode'>",
	                    width: "20ch"
                    },{
                        field: "fullName",
                        headerTemplate: "<span translate='modules.application.list.fullName'>"
                    },{
                        field: "applSum",
                        headerTemplate: "<span translate='modules.application.list.applSum'>",
                        format: "{0:n2}",
                        attributes: {class:"text-right"},
                        width: "11ch",
                        filterable: false
                    },{
                        field: "checkResult",
                        headerTemplate: "<span translate='modules.application.list.checkResult'>",
                        template: "<span translate='modules.application.checkResult.#:checkResult#'></span>",
	                    width: "18ch"
                    },{
                    	headerTemplate: "<span translate='modules.application.list.actions'>",
                    	template: "<button ng-hide='#:isEditable#' kendo-tooltip k-position=\"'top'\" k-content=\"'{{ 'entity.action.view' | translate }}'\" class='btn btn-default k-button-icontext' ui-sref='modules.applications.detail({id: #:id#})'>"
                                + "<span class='glyphicon glyphicon glyphicon glyphicon-eye-open'></span>"
                                + "</button>"
                                + "<button ng-show='#:isEditable#' kendo-tooltip k-position=\"'top'\" k-content=\"'{{ 'entity.action.edit' | translate }}'\" class='btn btn-default k-button-icontext' ui-sref='modules.applications.detail({id: #:id#})'>"
                                + "<span class='glyphicon glyphicon glyphicon-pencil'></span>"
                                + "</button>"
                                + "<button ng-show='#:canCreateLoan#' kendo-tooltip k-position=\"'top'\" k-content=\"'{{ 'modules.application.list.canCreateLoan' | translate }}'\" class='btn btn-success k-button-icontext' ui-sref='modules.loans.new({ applId: #:id# })'>"
                                + "<span class='fa fa-file-text-o'></span>"
                                + "</button>",
                        title: "&nbsp;", 
                        width: "13ch" 
                    }]
        };

        var run = $interval(reload, vm.refresh);
        
        function reload(){
        	vm.mainData.read();
        	vm.mainData.fetch(function(){
	        	  var data = vm.mainData.data();
	        	  var inProgressCounter = 0;
	        	  for (var i = 0; i < data.length; i++) {
		        	  if( data[i].applStatus == "NEW" ||
		                  data[i].applStatus == "QUEUE_AUTO" || 
		                  data[i].applStatus == "QUEUE_MANUAL" || 
		                  data[i].applStatus == "IN_PROGRESS" ) inProgressCounter++;
	        	  }
	        	  vm.refresh = inProgressCounter > 0 ? shortRefresh: longRefresh;
	        });
        }
        
        $scope.$watch(function() { return vm.refresh;}, function(newVal, oldVal) {
        	$interval.cancel(run);
        	run = $interval(reload, vm.refresh);
        }, true);
        $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) { 
        	$interval.cancel(run);
        });
    }
})();
