(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('DetailEmailClientController', DetailEmailClientController);

    DetailEmailClientController.$inject = ['$scope', '$state', 'currentClient', '$filter', '$stateParams', 'formHelper', 'detailMode', 'AlertService', '$translate', 'Client'];

    function DetailEmailClientController ($scope, $state, currentClient, $filter, $stateParams, formHelper, detailMode, AlertService, $translate, Client) {
        var vm = this;
        console.log("Detail Email Client Controller started");
        vm.clnId = currentClient[0].id;
        vm.kendo = kendo;

    	
    	vm.isViewOnly = !detailMode.edit;
    	vm.isNew = detailMode.create;
        
    	if(!vm.isViewOnly || vm.isNew) formHelper.setFocus('first-field');
    	
        if($state.params.emlId == "" && !vm.isNew) $state.go('modules.clients.emails');
        	
        if(!vm.isNew) {	
        	vm.emlId = $state.params.emlId;
	        vm.emails = currentClient[0].email;
	        vm.currentEmail = $filter('filter')(vm.emails, {id: $stateParams.emlId}, false)[0];
	        var emDate = vm.currentEmail.updtOn > vm.currentEmail.crtdOn ? vm.currentEmail.updtOn : vm.currentEmail.crtdOn;
	        vm.emailDate = kendo.toString(emDate, "d");
        } else {
        	vm.currentEmail = {};
        }       
        
        vm.statusDataSource = new kendo.data.DataSource({
    		data: [
			{
    			key: "UNKNOWN",
    			value: $translate.instant("modules.client.dataStatus.unknown")
    		},{
    			key: "CONFIRMED",
    			value: $translate.instant("modules.client.dataStatus.confirmed")
    		},{
    			key: "INVALID",
    			value: $translate.instant("modules.client.dataStatus.invalid")
    		}]
    	});
          
        vm.typeDataSource = new kendo.data.DataSource({
    		data: [
			{
    			key: "UNKNOWN",
    			value: $translate.instant("modules.client.emailType.unknown")
    		},			{
    			key: "WORK",
    			value: $translate.instant("modules.client.emailType.work")
    		},			{
    			key: "PERSONAL",
    			value: $translate.instant("modules.client.emailType.personal")
    		}]
    	});
        
    	vm.save = function() {
    		vm.currentEmail.fclId = currentClient[0].id;
    		console.log("saving changes in Detail client ...");
    		if(vm.isNew) {
    			Client.createEmail(
    					{clnId: vm.clnId}, vm.currentEmail,
    					function(responce){
    						AlertService.success("Client's email created successfully");
    						$state.go('modules.clients.emails', {}, {reload: true});
    					});
    		} else {
    			Client.updateEmail(
    					{clnId: vm.clnId, emlId: vm.emlId}, vm.currentEmail,
    					function(responce){
    						AlertService.success("Client's email updated successfully");
    						$state.go('modules.clients.emails.view', {emlId: vm.emlId}, {reload: true});
    					});
    		}    		
			
		};
		
		vm.cancel = function(){
			if(vm.isNew)
				$state.go("modules.clients.emails");
			else 
				$state.go("modules.clients.emails.view",{emlId:vm.emlId});
		};
        
    }
})();
