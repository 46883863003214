(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('PaymentListLoanController', PaymentListLoanController);

    PaymentListLoanController.$inject = ['$scope', '$state', '$stateParams', 'payments', 'defaultPageSize'];

    function PaymentListLoanController ($scope, $state, $stateParams, payments, defaultPageSize) {
        var vm = this;
        vm.kendo = kendo;
        console.log("PaymentListLoanController started");
        vm.loanPaymentsDataSource = new kendo.data.DataSource({
    		schema: {
			      model: {
			        fields: {
			          "dat": { type: "date" },
			          "bankCode": { type: "string" },
			          "bankName": { type: "string" },
			          "des": { type: "string" },
			          "num": { type: "number" },
			          "idCdh": { type: "number" },
			          "amount": { type: "number" },
			          "paid4avn": { type: "number" },
			          "paid4prn": { type: "number" },
			          "paid4int": { type: "number" },
			          "paid4pen": { type: "number" },
			          "paid4oth": { type: "number" },
			          "paid4moksut": { type: "number" }
			        }
			      }
    		},
    		data: payments,
			aggregate: [
                        { 
                        	field: "dat", 
                        	aggregate: "count"
                        },{ 
                        	field: "amount", 
                        	aggregate: "sum"
                        },{ 
                        	field: "paid4avn", 
                        	aggregate: "sum"
                        },{ 
                        	field: "paid4prn", 
                        	aggregate: "sum"
                        },{ 
                        	field: "paid4int", 
                        	aggregate: "sum"
                        },{ 
                        	field: "paid4pen", 
                        	aggregate: "sum"
                        },{ 
                        	field: "paid4oth", 
                        	aggregate: "sum"
                        }                            
            ],
            pageSize: defaultPageSize,
	        serverPaging: false,
	        serverFiltering: false,
	        serverSorting: false,
	        sort: {
	        	field: "num",
	        	dir: "desc"
	        }
        });
        
        vm.loanPaymentsOptions = {
        		scrollable: true,
                sortable: true,                
                filterable: false,
                pageable: {
                	messages: {
                	      empty: "No data"
                	}
                },
                toolbar: [
                          { 
  		                    template: "<span style='line-height:22px;' translate='modules.loan.titles.payments'></span>"
  		                    		 + "<button style='margin-right:1em;' class='pull-right btn btn-default btn-xs k-grid-excel'>"
  		                    		 + "<span class='fa fa-file-excel-o'></span>"
  		                             + "&nbsp;<span translate='modules.loan.excel'>"
  		                             + "</span></button>"
                            }
                ],
                excel: {
                	fileName: "loan-payments.xlsx"
                },
                columns: [
                    { 
                    	headerTemplate: "<span translate='modules.loan.table.paymentInfo'>",
                    	columns: [ {
	                         field:"num",
	 	                     headerTemplate: "<span translate='modules.loan.table.no'>",
	                         filterable: false,
	                         sortable: true,
	                         attributes: { "class": "text-center" },
	                         width: "5ch"
	                    },{
	                         field:"dat",
	                         headerTemplate: "<span translate='modules.loan.table.date'>",
	                         filterable: true,
	                         sortable: false,
	                         format: "{0:d}",
	                         aggregates: ["count"], 
	                         footerTemplate: "Kiekis: #=count#",
	                         footerAttributes: { "class": "text-center" },
	                         attributes: { "class": "text-center" },
	                         width: "15ch"
	                    },{
	                         field: "amount",
	                         headerTemplate: "<span translate='modules.loan.table.sum'>",
	                         sortable: false,
	                         filterable: false,
							 template: "#= (amount == 0 || amount == null) ? '' : kendo.toString(amount, 'n2') #",
	                         attributes: {class:"text-right"},
	                         aggregates: ["sum"], 
	                         footerTemplate: "#=kendo.toString(sum, 'n2')#",
	                         footerAttributes: { style: "text-align: right; " },
	                         width: "10ch"
	                    },{
	                         field: "bankCode",
	                         headerTemplate: "<span translate='modules.loan.table.bank'>",
	                         sortable: false,
	                         filterable: false,
	                         template: "<span title='#=bankName#'>#=bankCode#</span>",
	                         attributes: { "class": "text-center" },
	                         width: "8ch"
	                    }] 
                    },{ 
                    	headerTemplate: "<span translate='modules.loan.table.paymentDistribution'>",
                        columns: [{
	                         field: "paid4avn",
	                         headerTemplate: "<span translate='modules.loan.table.avans'>",
	                         template: "#= (paid4avn == 0 || paid4avn == null) ? '' : kendo.toString(paid4avn, 'n2') #",
	                         attributes: {class:"text-right"},
	                         sortable: false,
	                         filterable: false,
	                         aggregates: ["sum"], 
	                         footerTemplate: "#=kendo.toString(sum, 'n2')#",
	                         footerAttributes: { style: "text-align: right; " },
	                         width: "11ch"
	                     },{
	                         field: "paid4prn",
	                         title: "Kreditas",
	                         headerTemplate: "<span translate='modules.loan.table.credit'>",
	                         template: "#= (paid4prn == 0 || paid4prn == null) ? '' : kendo.toString(paid4prn, 'n2') #",
	                         attributes: {class:"text-right"},
	                         sortable: false,
	                         filterable: false,
	                         aggregates: ["sum"], 
	                         footerTemplate: "#=kendo.toString(sum, 'n2')#",
	                         footerAttributes: { style: "text-align: right; " },
	                         width: "11ch"
	                     },{
	                         field: "paid4int",
	                         headerTemplate: "<span translate='modules.loan.table.interest'>",
	                         template: "#= (paid4int == 0 || paid4int == null) ? '' : kendo.toString(paid4int, 'n2') #",
	                         attributes: {class:"text-right"},
	                         sortable: false,
	                         filterable: false,
	                         aggregates: ["sum"], 
	                         footerTemplate: "#=kendo.toString(sum, 'n2')#",
	                         footerAttributes: { style: "text-align: right; " },
	                         width: "11ch"
	                     },{
	                         field: "paid4pen",
	                         headerTemplate: "<span translate='modules.loan.table.fine'>",
	                         template: "#= (paid4pen == 0 || paid4pen == null) ? '' : kendo.toString(paid4pen, 'n2') #",
	                         attributes: {class:"text-right"},
	                         sortable: false,
	                         filterable: false,
	                         aggregates: ["sum"], 
	                         footerTemplate: "#=kendo.toString(sum, 'n2')#",
	                         footerAttributes: { style: "text-align: right; " },
	                         width: "13ch"
	                     },{
	                         field: "paid4oth",
	                         title: "Other",
	                         headerTemplate: "<span translate='modules.loan.table.other'>",
	                         template: "#= (paid4oth == 0 || paid4oth == null) ? '' : kendo.toString(paid4oth, 'n2') #",
	                         attributes: {class:"text-right"},
	                         sortable: false,
	                         filterable: false,
	                         aggregates: ["sum"], 
	                         footerTemplate: "#=kendo.toString(sum, 'n2')#",
	                         footerAttributes: { style: "text-align: right; " },
	                         width: "11ch"
	                     }]
                    },{
                         field: "des",
                         headerTemplate: "<span translate='modules.loan.table.note'>",
                         sortable: false,
                     }                            
                 ]
                          
        };
        
       
    }

})();
