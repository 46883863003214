(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('HeaderLoanController', HeaderLoanController);

    HeaderLoanController.$inject = ['$scope', '$state', 'menuWithHeader'];

    function HeaderLoanController ($scope, $state, menuWithHeader) {
        var vm = this;
        console.log("Header Loan Controller started");
        
        
        vm.loan = menuWithHeader[0];
        
        vm.fullName = vm.loan.firstName + " " + vm.loan.lastName;
        
//        var currentPageTemplate = $route.current.templateUrl;
//        $templateCache.remove(currentPageTemplate);
//        $route.reload();
        
//        if(header == null)
//        	vm.loan = entity;
//        else 
//        	vm.loan = header;
    }
})();
