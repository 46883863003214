(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        	.state('modules.clients', {
				url : '/clients/{clnId}',
				parent : 'modules',
				abstract: true,
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
	            },
				views : {
					'content@' : {
						templateUrl : 'app/modules/client/client.template.html',
						controller : 'ClientMenuController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
	                currentClient: ['$stateParams', 'Client', function($stateParams, Client) {
	                    return Client.get({clnId : $stateParams.clnId}).$promise;
	                }]
				}
			})
	       	.state('modules.clients.view', {
				url : '/view',
				parent : 'modules.clients',
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
	            },
				views : {
					'client-header': {
						templateUrl : 'app/modules/client/header/header.client.template.html',
						controller : 'HeaderClientController',
						controllerAs : 'vm'
					},
					'client-body' : {
						templateUrl : 'app/modules/client/detail.client.template.html',
						controller : 'DetailClientController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/client');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
					previousState: ["$state", function ($state) {
	                    var currentStateData = {
	                        name: $state.current.name || 'modules.clients',
	                        params: $state.params,
	                        url: $state.href($state.current.name, $state.params)
	                    };
	                    return currentStateData;
	                }],
	                detailMode: function(){
	                	return {
	                		edit: false,
	                		create: false
	                	};
	                }
				}
			})
			.state('modules.clients.edit', {
				url : '/edit',
				parent : 'modules.clients',
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
	            },
				views : {
					'client-header': {
						templateUrl : 'app/modules/client/header/header.client.template.html',
						controller : 'HeaderClientController',
						controllerAs : 'vm'
					},
					'client-body' : {
						templateUrl : 'app/modules/client/detail.client.template.html',
						controller : 'DetailClientController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/client');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
					previousState: ["$state", function ($state) {
	                    var currentStateData = {
	                        name: $state.current.name || 'modules.clients',
	                        params: $state.params,
	                        url: $state.href($state.current.name, $state.params)
	                    };
	                    return currentStateData;
	                }],
	                detailMode: function(){
	                	return {
	                		edit: true,
	                		create: false
	                	};
	                }
				}
			})
	       	.state('modules.clients.addresses', {
				url : '/addresses',
				parent : 'modules.clients',
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
	            },
				views : {
					'client-header': {
						templateUrl : 'app/modules/client/header/header.client.template.html',
						controller : 'HeaderClientController',
						controllerAs : 'vm'
					},
					'client-body' : {
						templateUrl : 'app/modules/client/addresses/addresses.client.template.html',
						controller : 'AddressesClientController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/client');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }]
				}
			})
			.state('modules.clients.addresses.view', {
				url : '/:adrId/view',
				parent : 'modules.clients.addresses',
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
	            },
				views : {
					'client-header': {
						templateUrl : 'app/modules/client/header/header.client.template.html',
						controller : 'HeaderClientController',
						controllerAs : 'vm'
					},
					'client-sub-header@modules.clients' : {
						templateUrl : 'app/modules/client/addresses/addresses.client.template.html',
						controller : 'AddressesClientController',
						controllerAs : 'vm'
					},
					'client-body@modules.clients' : {
						templateUrl : 'app/modules/client/addresses/detail.address.client.template.html',
						controller : 'DetailAddressClientController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/client');
	                	$translatePartialLoader.addPart('modules/application');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
					previousState: ["$state", function ($state) {
	                    var currentStateData = {
	                        name: $state.current.name || 'modules.clients.addresses',
	                        params: $state.params,
	                        url: $state.href($state.current.name, $state.params)
	                    };
	                    return currentStateData;
	                }],
	                detailMode: function(){
	                	return {
	                		edit: false,
	                		create: false
	                	};
	                }
				}
			})
			.state('modules.clients.addresses.edit', {
				url : '/:adrId/edit',
				parent : 'modules.clients.addresses',
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
	            },
				views : {
					'client-header': {
						templateUrl : 'app/modules/client/header/header.client.template.html',
						controller : 'HeaderClientController',
						controllerAs : 'vm'
					},
					'client-sub-header@modules.clients' : {
						templateUrl : 'app/modules/client/addresses/addresses.client.template.html',
						controller : 'AddressesClientController',
						controllerAs : 'vm'
					},
					'client-body@modules.clients' : {
						templateUrl : 'app/modules/client/addresses/detail.address.client.template.html',
						controller : 'DetailAddressClientController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/client');
	                	$translatePartialLoader.addPart('modules/application');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
					previousState: ["$state", function ($state) {
	                    var currentStateData = {
	                        name: $state.current.name || 'modules.clients.addresses',
	                        params: $state.params,
	                        url: $state.href($state.current.name, $state.params)
	                    };
	                    return currentStateData;
	                }],
	                detailMode: function(){
	                	return {
	                		edit: true,
	                		create: false
	                	};
	                }
				}
			})
			.state('modules.clients.addresses.add', {
				url : '/add',
				parent : 'modules.clients.addresses',
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
	            },
				views : {
					'client-header': {
						templateUrl : 'app/modules/client/header/header.client.template.html',
						controller : 'HeaderClientController',
						controllerAs : 'vm'
					},
					'client-sub-header@modules.clients' : {
						templateUrl : 'app/modules/client/addresses/addresses.client.template.html',
						controller : 'AddressesClientController',
						controllerAs : 'vm'
					},
					'client-body@modules.clients' : {
						templateUrl : 'app/modules/client/addresses/detail.address.client.template.html',
						controller : 'DetailAddressClientController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/client');
	                	$translatePartialLoader.addPart('modules/application');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
					previousState: ["$state", function ($state) {
	                    var currentStateData = {
	                        name: $state.current.name || 'modules.clients.addresses',
	                        params: $state.params,
	                        url: $state.href($state.current.name, $state.params)
	                    };
	                    return currentStateData;
	                }],
	                detailMode: function(){
	                	return {
	                		edit: true,
	                		create: true
	                	};
	                }
				}
			})
			.state('modules.clients.addresses.delete', {
				url : '/:adrId/delete',
				params: {currentAddress: null},
				parent : 'modules.clients.addresses',
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
	            },
	            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
	                $uibModal.open({
	                    templateUrl: 'app/modules/client/addresses/delete.address.client.template.html',
	                    controller: 'DeleteAddressClientController',
	                    controllerAs: 'vm',
	                    size: 'md'
	                }).result.then(function() {
	                    $state.go('modules.clients.addresses', null, { reload: true });
	                }, function() {
	                    $state.go('modules.clients.addresses.edit', {adrId: $state.params.adrId});
	                });
	            }]
	        })
			.state('modules.clients.emails', {
				url : '/emails',
				parent : 'modules.clients',
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
	            },
				views : {
					'client-header': {
						templateUrl : 'app/modules/client/header/header.client.template.html',
						controller : 'HeaderClientController',
						controllerAs : 'vm'
					},
					'client-body' : {
						templateUrl : 'app/modules/client/emails/emails.client.template.html',
						controller : 'EmailsClientController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/client');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }]
				}
			})
			.state('modules.clients.emails.view', {
				url : '/:emlId/view',
				parent : 'modules.clients.emails',
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
	            },
				views : {
					'client-header': {
						templateUrl : 'app/modules/client/header/header.client.template.html',
						controller : 'HeaderClientController',
						controllerAs : 'vm'
					},
					'client-sub-header@modules.clients' : {
						templateUrl : 'app/modules/client/emails/emails.client.template.html',
						controller : 'EmailsClientController',
						controllerAs : 'vm'
					},
					'client-body@modules.clients' : {
						templateUrl : 'app/modules/client/emails/detail.email.client.template.html',
						controller : 'DetailEmailClientController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/client');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
					previousState: ["$state", function ($state) {
	                    var currentStateData = {
	                        name: $state.current.name || 'modules.clients.emails',
	                        params: $state.params,
	                        url: $state.href($state.current.name, $state.params)
	                    };
	                    return currentStateData;
	                }],
	                detailMode: function(){
	                	return {
	                		edit: false,
	                		create: false
	                	};
	                }
				}
			})
			.state('modules.clients.emails.edit', {
				url : '/:emlId/edit',
				parent : 'modules.clients.emails',
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
	            },
				views : {
					'client-header': {
						templateUrl : 'app/modules/client/header/header.client.template.html',
						controller : 'HeaderClientController',
						controllerAs : 'vm'
					},
					'client-sub-header@modules.clients' : {
						templateUrl : 'app/modules/client/emails/emails.client.template.html',
						controller : 'EmailsClientController',
						controllerAs : 'vm'
					},
					'client-body@modules.clients' : {
						templateUrl : 'app/modules/client/emails/detail.email.client.template.html',
						controller : 'DetailEmailClientController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/client');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
					previousState: ["$state", function ($state) {
	                    var currentStateData = {
	                        name: $state.current.name || 'modules.clients.emails',
	                        params: $state.params,
	                        url: $state.href($state.current.name, $state.params)
	                    };
	                    return currentStateData;
	                }],
	                detailMode: function(){
	                	return {
	                		edit: true,
	                		create: false
	                	};
	                }
				}
			})
			.state('modules.clients.emails.add', {
				url : '/add',
				parent : 'modules.clients.emails',
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
	            },
				views : {
					'client-header': {
						templateUrl : 'app/modules/client/header/header.client.template.html',
						controller : 'HeaderClientController',
						controllerAs : 'vm'
					},
					'client-sub-header@modules.clients' : {
						templateUrl : 'app/modules/client/emails/emails.client.template.html',
						controller : 'EmailsClientController',
						controllerAs : 'vm'
					},
					'client-body@modules.clients' : {
						templateUrl : 'app/modules/client/emails/detail.email.client.template.html',
						controller : 'DetailEmailClientController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/client');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
					previousState: ["$state", function ($state) {
	                    var currentStateData = {
	                        name: $state.current.name || 'modules.clients.emails',
	                        params: $state.params,
	                        url: $state.href($state.current.name, $state.params)
	                    };
	                    return currentStateData;
	                }],
	                detailMode: function(){
	                	return {
	                		edit: true,
	                		create: true
	                	};
	                }
				}
			})
			.state('modules.clients.emails.delete', {
				url : '/:emlId/delete',
				params: {currentEmail: null},
				parent : 'modules.clients.emails',
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
	            },
	            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
	                $uibModal.open({
	                    templateUrl: 'app/modules/client/emails/delete.email.client.template.html',
	                    controller: 'DeleteEmailClientController',
	                    controllerAs: 'vm',
	                    size: 'md'
	                }).result.then(function() {
	                    $state.go('modules.clients.emails', null, { reload: true });
	                }, function() {
	                    $state.go('modules.clients.emails.edit', {emlId: $state.params.emlId});
	                });
	            }]
	        })
	        .state('modules.clients.phones', {
				url : '/phones',
				parent : 'modules.clients',
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
	            },
				views : {
					'client-header': {
						templateUrl : 'app/modules/client/header/header.client.template.html',
						controller : 'HeaderClientController',
						controllerAs : 'vm'
					},
					'client-body' : {
						templateUrl : 'app/modules/client/phones/phones.client.template.html',
						controller : 'PhonesClientController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/client');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }]
				}
			})
			.state('modules.clients.phones.view', {
				url : '/:phnId/view',
				parent : 'modules.clients.phones',
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
	            },
				views : {
					'client-header': {
						templateUrl : 'app/modules/client/header/header.client.template.html',
						controller : 'HeaderClientController',
						controllerAs : 'vm'
					},
					'client-sub-header@modules.clients' : {
						templateUrl : 'app/modules/client/phones/phones.client.template.html',
						controller : 'PhonesClientController',
						controllerAs : 'vm'
					},
					'client-body@modules.clients' : {
						templateUrl : 'app/modules/client/phones/detail.phone.client.template.html',
						controller : 'DetailPhoneClientController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/client');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
					previousState: ["$state", function ($state) {
	                    var currentStateData = {
	                        name: $state.current.name || 'modules.clients.phones',
	                        params: $state.params,
	                        url: $state.href($state.current.name, $state.params)
	                    };
	                    return currentStateData;
	                }],
	                detailMode: function(){
	                	return {
	                		edit: false,
	                		create: false
	                	};
	                }
				}
			})			
			.state('modules.clients.phones.edit', {
				url : '/:phnId/edit',
				parent : 'modules.clients.phones',
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
	            },
				views : {
					'client-header': {
						templateUrl : 'app/modules/client/header/header.client.template.html',
						controller : 'HeaderClientController',
						controllerAs : 'vm'
					},
					'client-sub-header@modules.clients' : {
						templateUrl : 'app/modules/client/phones/phones.client.template.html',
						controller : 'PhonesClientController',
						controllerAs : 'vm'
					},
					'client-body@modules.clients' : {
						templateUrl : 'app/modules/client/phones/detail.phone.client.template.html',
						controller : 'DetailPhoneClientController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/client');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
					previousState: ["$state", function ($state) {
	                    var currentStateData = {
	                        name: $state.current.name || 'modules.clients.phones',
	                        params: $state.params,
	                        url: $state.href($state.current.name, $state.params)
	                    };
	                    return currentStateData;
	                }],
	                detailMode: function(){
	                	return {
	                		edit: true,
	                		create: false
	                	};
	                }
				}
			})			
			.state('modules.clients.phones.add', {
				url : '/add',
				parent : 'modules.clients.phones',
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
	            },
				views : {
					'client-header': {
						templateUrl : 'app/modules/client/header/header.client.template.html',
						controller : 'HeaderClientController',
						controllerAs : 'vm'
					},
					'client-sub-header@modules.clients' : {
						templateUrl : 'app/modules/client/phones/phones.client.template.html',
						controller : 'PhonesClientController',
						controllerAs : 'vm'
					},
					'client-body@modules.clients' : {
						templateUrl : 'app/modules/client/phones/detail.phone.client.template.html',
						controller : 'DetailPhoneClientController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/client');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
					previousState: ["$state", function ($state) {
	                    var currentStateData = {
	                        name: $state.current.name || 'modules.clients.phones',
	                        params: $state.params,
	                        url: $state.href($state.current.name, $state.params)
	                    };
	                    return currentStateData;
	                }],
	                detailMode: function(){
	                	return {
	                		edit: true,
	                		create: true
	                	};
	                }
				}
			})			
			.state('modules.clients.phones.delete', {
				url : '/:phnId/delete',
				params: {currentPhone: null},
				parent : 'modules.clients.phones',
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
	            },
	            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
	                $uibModal.open({
	                    templateUrl: 'app/modules/client/phones/delete.phone.client.template.html',
	                    controller: 'DeletePhoneClientController',
	                    controllerAs: 'vm',
	                    size: 'md'
	                }).result.then(function() {
	                    $state.go('modules.clients.phones', null, { reload: true });
	                }, function() {
	                	$state.go('modules.clients.phones.edit', {phnId: $state.params.phnId});
	                });
	            }]
	        })
	       	.state('modules.clients.history', {
				url : '/history',
				parent : 'modules.clients',
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
	            },
				views : {
					'client-header': {
						templateUrl : 'app/modules/client/header/header.client.template.html',
						controller : 'HeaderClientController',
						controllerAs : 'vm'
					},
					'client-sub-header' : {
						templateUrl : 'app/modules/client/history/history.client.template.html',
						controller : 'HistoryClientController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/client');
	                	$translatePartialLoader.addPart('modules/history');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }]
				}
			})
			;
        
        
    }
})();
