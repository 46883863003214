(function () {
    "use strict";

    angular.module("sigmaApp")

    .constant("baseUrl", "/w/s/")
    .constant("defaultPageSize", 10)
    .constant("shortRefresh", 20000)
    .constant("longRefresh", 60000)
    ;
})();