(function() {
	'use strict';
	angular.module('sigmaApp').factory('Loan', Loan);

	Loan.$inject = [ '$resource', 'baseUrl', 'DateUtils'];

	function Loan($resource, baseUrl, DateUtils) {
		var resourceUrl = baseUrl + 'api/loans/:loan_no';

		return $resource(resourceUrl,{},
				{
					'query' : {
						method : 'GET',
						isArray : true,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'create' : {
						method : 'POST',
						url: baseUrl + 'api/loans',
						transformRequest : function(data) {
							//data.applDate = DateUtils.convertLocalDateToServer(data.applDate);
							return angular.toJson(data);
						},
						transformResponse : function(data) {
							var response = {};
							if(data) {
								response = angular.fromJson(data)
								if(response.err === "Error") {
									var msg = "";
									angular.forEach(response.message.split('|'), function(item){
										msg += $filter('translate')(item) + '\n'; 
									});
									response.message = msg;
								}
							}
							return response;
						}
					},
					'delete' : {
						method : 'DELETE'
					},
					'get' : {
						method : 'GET',
						isArray : true,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'getPayments' : {
						method : 'GET',
						url: resourceUrl + "/payments", 
						isArray : true,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'getSchedules' : {
						method : 'GET',
						url: resourceUrl + "/schedules", 
						isArray : true,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'getOperations' : {
						method : 'GET',
						url: resourceUrl + "/operations", 
						isArray : true,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'getInterests' : {
						method : 'GET',
						url: resourceUrl + "/interestrates", 
						isArray : true,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'updateInterest': {
						method:'PUT',
						url: baseUrl + "api/loans/interestrates"
					},
					'createInterest': {
						method:'POST',
						url: baseUrl + "api/loans/interestrates"
					},
					'deleteInterest': {
						method:'DELETE',
						url: baseUrl + "api/loans/:id/interestrates/:rateId"
					},
					'getHistory' : {
						method : 'GET',
						url: resourceUrl + "/history", 
						isArray : true,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					}
				});
	}
})();