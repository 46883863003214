(function() {
	'use strict';
	angular.module('sigmaApp').factory('Mvc', Mvc);

	Mvc.$inject = [ '$resource', 'baseUrl', 'DateUtils'];

	function Mvc($resource, baseUrl, DateUtils) {
		var resourceUrl = baseUrl + 'api/gps/mvc';

		return $resource(resourceUrl,{},
				{
					'getOperations' : {
						method : 'GET',
						url: resourceUrl + "/operations/:d",
						isArray : false,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'getEvents' : {
						method : 'GET',
						url: resourceUrl + "/events",
						isArray : false,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'getBalances' : {
						method : 'GET',
						url: resourceUrl + "/balances",
						isArray : false,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					}
				
				});
	}
})();