(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('DetailPhoneClientController', DetailPhoneClientController);

    DetailPhoneClientController.$inject = ['$scope', '$state', 'currentClient', '$filter', '$stateParams', 'formHelper', 'detailMode', 'AlertService', '$translate', 'Client'];

    function DetailPhoneClientController ($scope, $state, currentClient, $filter, $stateParams, formHelper, detailMode, AlertService, $translate, Client) {
        var vm = this;
        console.log("Detail Phone Client Controller started");
        vm.clnId = currentClient[0].id;
        vm.kendo = kendo;

    	vm.isViewOnly = !detailMode.edit;
    	vm.isNew = detailMode.create;
        
    	if(!vm.isViewOnly || vm.isNew) formHelper.setFocus('first-field');
    	
        if($state.params.phnId == "" && !vm.isNew) $state.go('modules.clients.phones');
        	
        if(!vm.isNew) {	
        	vm.phnId = $state.params.phnId;
	        vm.phones = currentClient[0].phone;
	        vm.currentPhone = $filter('filter')(vm.phones, {id: $stateParams.phnId}, false)[0];
	        var phDate = vm.currentPhone.updtOn > vm.currentPhone.crtdOn ? vm.currentPhone.updtOn : vm.currentPhone.crtdOn ;
	        vm.phoneDate = kendo.toString(phDate, "d");
        } else {
        	vm.currentPhone = {};
        }       
        

        vm.statusDataSource = new kendo.data.DataSource({
    		data: [
			{
    			key: "UNKNOWN",
    			value: $translate.instant("modules.client.dataStatus.unknown")
    		},{
    			key: "CONFIRMED",
    			value: $translate.instant("modules.client.dataStatus.confirmed")
    		},{
    			key: "INVALID",
    			value: $translate.instant("modules.client.dataStatus.invalid")
    		}]
    	});
        
        vm.typeDataSource = new kendo.data.DataSource({
    		data: [
			{
    			key: "UNKNOWN",
    			value: $translate.instant("modules.client.phoneType.unknown")
    		},{
    			key: "MOBILE",
    			value: $translate.instant("modules.client.phoneType.mobile")
    		},{
    			key: "HOME",
    			value: $translate.instant("modules.client.phoneType.home")
    		},{
    			key: "WORK",
    			value: $translate.instant("modules.client.phoneType.work")
    		},]
    	});
        
    	vm.save = function() {
    		vm.currentPhone.fclId = currentClient[0].id;
    		vm.number = true;
    		console.log("saving changes in Detail client ...");
    		if(vm.currentPhone.number.length == 1 && vm.currentPhone.number == "+") vm.currentPhone.number = "";
    		if(vm.isNew) {
    			Client.createPhone(
    					{clnId: vm.clnId}, vm.currentPhone,
    					function(responce){
    						AlertService.success("Client's phone created successfully");
    						$state.go('modules.clients.phones', {}, {reload: true});
    					});
    		} else {
    			Client.updatePhone(
    					{clnId: vm.clnId, phnId: vm.phnId}, vm.currentPhone,
    					function(responce){
    						AlertService.success("Client's phone updated successfully");
    						$state.go('modules.clients.phones.view', {phnId: vm.phnId}, {reload: true});
    					});
    		}    		
			
		};
		
		vm.cancel = function(){
			if(vm.isNew)
				$state.go("modules.clients.phones");
			else 
				$state.go("modules.clients.phones.view",{phnId:vm.phnId});
		};
        
    }
})();
