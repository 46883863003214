(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('TasksBpmController', TasksBpmController);

    TasksBpmController.$inject = ['$scope', '$state', 'Bpm', 'defaultPageSize', '$translate'];

    function TasksBpmController ($scope, $state, Bpm, defaultPageSize, $translate) {
        var vm = this;
        console.log("TasksBpmController started");
        vm.tasks = [];
        vm.bpmTasksDataSource = new kendo.data.DataSource({
	        schema: {
	        	model: {
	        			id: "taskId",
				        fields: {
				          "taskId": { type: "number" },
				          "businessKey": { type: "string" },
				          "executor": { type: "string" },
				          "dueDate": { type: "date" },
				          "enqueued": { type: "date" },
				          "leasedTill": { type: "date" },
				          "priority": { type: "number" },
				          "retries": { type: "number" },
				          "errorMessage": { type: "string" },
				          "stackTrace": { type: "string" }
				        }
				      }
	  		},
//	  		data: tasks,
	  		transport: {
				read: function(e){
	  				Bpm.getTasks(
							function(response){
								vm.tasks = response;
								e.success(response);
							},
							function(error){
								console.log('task list error');
								e.error(error);
							}
					);
				}
			},
	        pageSize: defaultPageSize,
	        serverPaging: false,
	        serverFiltering: false,
	        serverSorting: false,
	        sort: {
	              field: "taskId",
	              dir: "desc"
	        }
        });
        
        vm.bpmTasksOptions = {
        		scrollable: true,
                sortable: true,                
                filterable: true,
                pageable: {
                	refresh: true,
                	messages: {
                	      empty: "No data"
                	}
                },
                toolbar: [
                          { 
    		                    template: "<span style='line-height:22px;' translate='modules.bpm.titles.tasks'></span>"
 		                    		 + "<button style='margin-right:1em;' class='pull-right btn btn-default btn-xs k-grid-excel'>"
 		                    		 + "<span class='fa fa-file-excel-o'></span>"
 		                             + "&nbsp;<span translate='modules.bpm.excel'>"
 		                             + "</span></button>"
                          }
                ],
                excel: {
                	fileName: "bpm-tasks.xlsx",
                    allPages: true
                },
                excelExport: function(e) {
                	e.workbook.sheets[0].columns.forEach(function(column){
                		column.autoWidth = true;
                	});
//                	e.workbook.sheets[0].columns = [
//                		{ width:100 },
//                		{ width:300 },
//                		{ width:400 },
//                		// ...
//                	];
                    e.workbook.sheets[0].rows.forEach(function(row){
                      if(row.type != 'data'){
                      	row.cells.forEach(function(cell){
                          cell.value = $translate.instant(cell.value);
                        });
                      }
                	});
                },                
                columns: [
						{ 
							field: "taskId", 
							title: "{{'modules.bpm.table.id'|translate}}",
							width: "7ch", 
							sortable: true,
							filterable: false
						},{ 
							field: "businessKey", 
							title: "{{'modules.bpm.table.businessKey'|translate}}",
							width: "20ch", 
							sortable: true,
							filterable: true
						},{ 
							field: "retries",
							title: "{{'modules.bpm.table.retries'|translate}}",
						    attributes: { class: "text-right" },
						    sortable: false,
						    filterable: true,
						    width: "10ch"
						},{
			                field: "executor",
			                title: "{{'modules.bpm.table.executor'|translate}}",
			                sortable: false,
			            },{
                          	title: "{{'modules.bpm.table.actions'|translate}}",
                        	template: "<button kendo-tooltip k-position=\"'top'\" k-content=\"'{{ 'entity.action.view' | translate }}'\" class='btn btn-default k-button-icontext' ng-click='vm.view(#:taskId#)'>"
                                + "<span class='glyphicon glyphicon glyphicon glyphicon-eye-open'></span>"
                                + "</button>",
		                    width: "10ch" 
                          }  
				]	
                            	
        	};
        
	        vm.view = function(id) {
	            $state.go('modules.bpm.tasks.detail',{taskId: id, tasks: vm.tasks});
	        };
        
        };
    
    
})();
