(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('OperationListLoanController', OperationListLoanController);

    OperationListLoanController.$inject = ['$scope', '$state', 'operations', 'defaultPageSize'];

    function OperationListLoanController ($scope, $state, operations, defaultPageSize) {
        var vm = this;
        vm.kendo = kendo;
        console.log("OperationListLoanController started");
      	
        vm.loanOperationsDataSource = new kendo.data.DataSource({
    		schema: {
			      model: {
			        fields: {
			          "num": { type: "number" },
			          "setlDate": { type: "date" },
			          "cardDate": { type: "date" },
			          "trnAmt": { type: "number" },
			          "trnCurr": { type: "string" },
			          "billAmt": { type: "number" },
			          "billCurr": { type: "string" },
			          "billRate": { type: "number" },
			          "trnDesc": { type: "string" },
			          "termDesc": { type: "string" },
			          "idCdh": { type: "number" }
			        }
			      }
    		},
			data: operations,
            aggregate: [
                        { field: "cardDate", aggregate: "count"},
                        { field: "billAmt", aggregate: "sum"},
                      ],
            pageSize: defaultPageSize,
            serverPaging: false,
            serverFiltering: false,
            serverSorting: false,
            sort: {
                field: "num",
                dir: "desc"
            }
        });
        
        vm.loanOperationsOptions = {
        		scrollable: true,
                sortable: true,                
                filterable: false,
                pageable: {
                	messages: {
                	      empty: "No data"
                	}
                },
                toolbar: [
                          { 
		                    template: "<span style='line-height:22px;' translate='modules.loan.titles.operations'></span>"
		                    		 + "<button style='margin-right:1em;' class='pull-right btn btn-default btn-xs k-grid-excel'>"
		                    		 + "<span class='fa fa-file-excel-o'></span>"
		                             + "&nbsp;<span translate='modules.loan.excel'>"
		                             + "</span></button>"
                          }
                ],
                excel: {
                	fileName: "loan-operations.xlsx"
                },                
                columns: [
						{ 
							field: "num", 
							headerTemplate: "<span translate='modules.loan.table.no'>",
							width: "5ch", 
							sortable: true,
							filterable: false
						},{ 
                          	headerTemplate: "<span translate='modules.loan.table.card'>",
			                columns: [
										{ 
											field: "cardDate", 
					                        headerTemplate: "<span translate='modules.loan.table.date'>",
					                        width: "20ch",
					                        format: "{0:G}",
					                        filterable: true,
					                        sortable: false,
					                        aggregates: ["count"], 
					                        footerTemplate: "Kiekis: #=count#",
					                        footerAttributes: {class: "text-center"}
										},{ 
											field: "trnAmt",
											headerTemplate: "<span translate='modules.loan.table.sum'>",
											template: "#= (trnAmt == 0 || trnAmt == null) ? '' : kendo.toString(trnAmt, 'n2')# &nbsp;#= trnCurr #",
										    attributes: { class: "text-right" },
										    sortable: false,
										    filterable: false,
										    width: "17ch"
										}
							]
						},{ 
                            headerTemplate: "<span translate='modules.loan.table.account'>",
                            columns: [
		                            	{ 
											field: "setlDate", 
					                        headerTemplate: "<span translate='modules.loan.table.date'>",
					                        width: "12ch",
					                        format: "{0:d}",
					                        filterable: true,
					                        sortable: false,
										},{ 
											field: "billAmt",
											headerTemplate: "<span translate='modules.loan.table.sum'>",
											template: "#= (billAmt == 0 || billAmt == null) ? '' : kendo.toString(billAmt, 'n2')# &nbsp;#= billCurr #",
										    attributes: { class: "text-right" },
										    sortable: false,
										    filterable: false,
										    width: "13ch"
										},{
			                                field: "billRate",
			                                headerTemplate: "<span translate='modules.loan.table.rate'>",
			                                sortable: false,
			                                filterable: false,
			                                attributes: { "class": "text-right" },
			                                width: "11ch"
			                            } 
							] }, /*{
			                                field: "trnDesc",
			                                headerTemplate: "<span translate='modules.loan.table.note'>",
			                                sortable: false,
			                    },*/ {
			                    	field: "termDesc",
			                        headerTemplate: "<span translate='modules.loan.table.termDesc'>",
			                        sortable: false,
			                    }  
							]
                            	
                          };
       
    }
    
})();
