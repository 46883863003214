(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('ScheduleListLoanController', ScheduleListLoanController);

    ScheduleListLoanController.$inject = ['$scope', '$state', 'schedules', 'defaultPageSize'];

    function ScheduleListLoanController ($scope, $state, schedules, defaultPageSize) {
        var vm = this;
        vm.kendo = kendo;
        console.log("ScheduleListLoanController started");
      	
        vm.loanSchedulesDataSource = new kendo.data.DataSource({
    		schema: {
			      model: {
			        fields: {
			          "nr": { type: "number" },
			          "perFrom": { type: "date" },
			          "perTill": { type: "date" },
			          "oprDate": { type: "date" },
			          "payDate": { type: "date" },
			          "perAmt": { type: "number" },
			          "corrAmt": { type: "number" },
			          "intAmt": { type: "number" },
			          "mgrId": { type: "number" },
			        }
			      }
    		},
			data: schedules,
			aggregate: [
                        { field: "perFrom", aggregate: "count"},
                        { field: "perAmt", aggregate: "sum"},
                        { field: "corrAmt", aggregate: "sum"},
                        { field: "intAmt", aggregate: "sum"},
            ],
            pageSize: defaultPageSize,
            serverPaging: false,
            serverFiltering: false,
            serverSorting: false,
            sort: {
                field: "nr",
                dir: "asc"
            }
        });
        
        vm.loanSchedulesOptions = {
        		scrollable: true,
                sortable: true,                
                filterable: false,
                pageable: {
                	messages: {
                	      empty: "No data"
                	}
                },
                toolbar: [
                          { 
  		                    template: "<span style='line-height:22px;' translate='modules.loan.titles.schedules'></span>"
  		                    		 + "<button style='margin-right:1em;' class='pull-right btn btn-default btn-xs k-grid-excel'>"
  		                    		 + "<span class='fa fa-file-excel-o'></span>"
  		                             + "&nbsp;<span translate='modules.loan.excel'>"
  		                             + "</span></button>"
                            }
                ],
                excel: {
                	fileName: "loan-schedules.xlsx"
                },
                columns: [
							{ 
								field: "nr", 
								headerTemplate: "<span translate='modules.loan.table.no'>",
								width: "5ch", 
								filterable: false
							},{ 
								field: "perFrom", 
		                        headerTemplate: "<span translate='modules.loan.table.from'>",
		                        width: "12ch",
		                        format: "{0:d}"
							},{ 
								field: "perTill", 
		                        headerTemplate: "<span translate='modules.loan.table.to'>",
		                        width: "12ch",
		                        format: "{0:d}",
		                        filterable: false
							},{ 
								field: "oprDate", 
		                        headerTemplate: "<span kendo-tooltip k-position='\"top\"' k-content=\"'{{ 'modules.loan.table.date' | translate }}'\" translate='modules.loan.table.oprDate'>",
		                        width: "12ch",
		                        format: "{0:d}",
								filterable: false
							},{ 
								field: "payDate",
								headerTemplate: "<span translate='modules.loan.table.payDate'>",
								width: "12ch",
								format: "{0:d}",
								filterable: false
							},{ 
								field: "intAmt",
								headerTemplate: "<span kendo-tooltip k-position='\"top\"' k-content=\"'{{ 'modules.loan.table.amount' | translate }}'\" translate='modules.loan.table.intAmt'>",
								template: "#= (intAmt == 0 || intAmt == null) ? '' : kendo.toString(intAmt, 'n2') #",
								aggregates: ["sum"],
								footerTemplate: "#=kendo.toString(sum, 'n2')#",
							    attributes: { "class": "text-right" },
							    footerAttributes: { style: "text-align: right; " }, 
							    filterable: false 
							},{ 
								field: "corrAmt", 
								headerTemplate: "<span kendo-tooltip k-position='\"top\"' k-content=\"'{{ 'modules.loan.table.amount' | translate }}'\" translate='modules.loan.table.corrAmt'>",
								template: "#= (corrAmt == 0 || corrAmt == null) ? '' : kendo.toString(corrAmt, 'n2') #",
								aggregates: ["sum"], 
								footerTemplate: "#=kendo.toString(sum, 'n2')#",
							    attributes: { "class": "text-right" },
							    footerAttributes: { style: "text-align: right; " }, 
							    filterable: false 
							},{ 
								field: "perAmt",  
								headerTemplate: "<span kendo-tooltip k-position='\"top\"' k-content=\"'{{ 'modules.loan.table.amount' | translate }}'\" translate='modules.loan.table.perAmt'>",
								template: "#= (perAmt == 0 || perAmt == null) ? '' : kendo.toString(perAmt, 'n2') #", 
								aggregates: ["sum"], 
								footerTemplate: "#=kendo.toString(sum, 'n2')#",
							    attributes: { "class": "text-right" },
							    footerAttributes: { style: "text-align: right; " }, 
							    filterable: false
							},{
	                          	headerTemplate: "<span translate='modules.loan.actions'>",
	                        	template: "<button kendo-tooltip k-position=\"'top'\" k-content=\"'{{ 'entity.action.view' | translate }}'\" class='btn btn-default k-button-icontext' ui-sref='modules.loans.current.schedules.view({schId: #:nr#})'>"
	                                + "<span class='glyphicon glyphicon glyphicon glyphicon-eye-open'></span>"
	                                + "</button>",
			                        title: "&nbsp;", 
			                        width: "10ch" 
	                          }
                    ]
 
        };
      
       
    }
    
})();
