(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .directive('docLink', docLink)
    	.directive('onFinishRenderFix', onFinishRenderFix)
    	.directive('isAllowed', isAllowed)
    	.directive('isAnyAllowed', isAnyAllowed)
    	;
    	
    	isAllowed.$inject = ['Rights', '$compile'];
    
    	isAnyAllowed.$inject = ['Rights', '$compile'];
    	
    	function docLink() {
    		
    		var directive = {
    			template: '<a ui-sref="{{link}}">{{ innerText }}</a>',
    			replace: true,
    			restrict: 'EA',
    			scope: {
    				docId: '@docId',
    				docCode: '@docCode',
    				innerText: '@innerText'
    			},
    			controller: ['$scope', function($scope) {
    				
    				switch($scope.docCode.toUpperCase()){
    					case "APPLICATION":
    						$scope.link = "modules.applications.detail({id:"+ $scope.docId+"})";
    						break;
    				}
    		
    			}]
    		};

    		return directive;
    	}

    	function isAllowed(Rights,$compile) {
    		
    		var directive = {
    			restrict: 'A',
    		    priority: -100,
    		    terminal: false,
    			scope: {
    				isAllowed: '@isAllowed'
    			},
    			compile: function(tElement, tAttrs) {
    					tElement.removeAttr('is-allowed');
    					return function(scope, element, attrs) {
    						var notAllowed = !Rights.isAllowed(scope.isAllowed);
    						if(notAllowed) {
		    				    attrs.$set('ngDisabled', notAllowed);
		    				    attrs.$set('ngHide', notAllowed);
		    				    attrs.$set('ngReadonly', notAllowed);
		    				    
		    				    $compile(element)(scope);
    						}
    					};
    			}
    		};

    		return directive;
    	}    	
    	
    	function isAnyAllowed(Rights,$compile) {
    		
    		var directive = {
    			restrict: 'A',
    		    priority: -100,
    		    terminal: false,
    			scope: {
    				isAnyAllowed: '@isAnyAllowed'
    			},
    			compile: function(tElement, tAttrs) {
    					tElement.removeAttr('is-any-allowed');
    					return function(scope, element, attrs) {
    						var notAllowed = !Rights.isAnyAllowed(scope.isAnyAllowed);
    						if(notAllowed) {
		    				    attrs.$set('ngDisabled', notAllowed);
		    				    attrs.$set('ngHide', notAllowed);
		    				    attrs.$set('ngReadonly', notAllowed);
		    				    
		    				    $compile(element)(scope);
    						}
    					};
    			}
    		};

    		return directive;
    	}   
    	
    	function onFinishRenderFix(){
    		return function(scope, element, attrs){
                //var isTouchDevice = 'ontouchstart' in document.documentElement;
                //After last item is rendered, do the enclosed things
//                if(scope.$last && isTouchDevice === true){
//                    $('.overlay').removeClass('touch-placeholder');
//                    $('.button-planes').remove();
//                    $('.button-cars').remove();
//                    //Other things you want to do  
//                }
    			
    			var input = angular.element(element);
    			input.addClass("k-textboxssss");
            }
    	}
})();
