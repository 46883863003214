(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('InterestDetailLoanController', InterestDetailLoanController);

    InterestDetailLoanController.$inject = ['$scope', '$state', '$stateParams', 'Loan', 'defaultPageSize', 'interests', '$filter', 'detailMode', 'previousState'];

    function InterestDetailLoanController ($scope, $state, $stateParams, Loan, defaultPageSize, interests, $filter, detailMode, previousState) {
        var vm = this;
        vm.kendo = kendo;
        vm.editMode = detailMode.edit;
      	vm.createMode = detailMode.create;
        console.log("InterestDetailLoanController started");
        if(!vm.createMode) {
	      	vm.currentInterest = $filter('filter')(interests, {id: $stateParams.rateId}, false)[0];
        } 
      	
      	
      	
      	vm.edit = function(){
      		$state.go('modules.loans.current.interests.edit', {rateId: $stateParams.rateId});
      	};
      	
      	vm.cancel = function(){
      		$state.go('modules.loans.current.interests.view', {rateId: $stateParams.rateId});
      	};
      	
      	vm.remove = function(){
      		console.log('deleting');
      		Loan.deleteInterest({id: vm.currentInterest.loan, rateId: vm.currentInterest.id},function(response){
      			$state.go('modules.loans.current.interests', {}, {reload: true});
      		});
      	};
      	
      	vm.save = function(){
      		console.log('saving');
      		if(vm.createMode)
	      		Loan.createInterest(vm.currentInterest,function(response){
	      			vm.currentInterest = response;
	      			vm.currentInterest.id = 1; // for mock
	      			if(vm.currentInterest.id > 0)
	      				$state.go('modules.loans.current.interests.view', {rateId: vm.currentInterest.id}, {reload: true});
	      		});
      		else
      			Loan.updateInterest(vm.currentInterest,function(response){
	      			vm.currentInterest = response;
	      			$state.go('modules.loans.current.interests.view', {rateId: vm.currentInterest.id}, {reload: true});
	      		});
      	};

    }
    
})();
