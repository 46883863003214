(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('HistoryLoanController', HistoryLoanController);

    HistoryLoanController.$inject = ['$scope', '$state'];

    function HistoryLoanController ($scope, $state) {
        var vm = this;
        console.log("HistoryLoanController started");
        vm.loanId =  $state.params.id;
    }
    
})();
