(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('NewLoanController', NewLoanController);

    NewLoanController.$inject = ['$scope', '$state', '$stateParams', 'application', 'AlertService', 'Calculator', '$translate', 'detailMode', 'Loan', 'previousState'];

    function NewLoanController ($scope, $state, $stateParams, application, AlertService, Calculator, $translate, detailMode, Loan, previousState) {
        var vm = this;
        console.log("NewLoanController ...");
        vm.isNew = detailMode.isNew;
        vm.hasReservedLoanNo = application.hasOwnProperty('reservedLoanNo') && application.reservedLoanNo != "";
        
        
        vm.back = function(){
        	
        	if(previousState.name == 'modules.applications')
        		$state.go('modules.applications', {}, {reload: true});
        	else 
        		$state.go('modules.applications.detail', {id: $state.params.applId}, {reload: true});
        };
        
        	vm.loan = {};
        	
	        if(application == null || application.id == null || application.id <= 0 || application.id == '') {
	        	var msg = 'Application is not provided';
	        	console.log(msg);
	        	AlertService.warning(msg);
	        	$state.go('modules.applications');
	        	return;
	        }
	        if(!(application.result != null && application.result.length > 0) || 
	        		!(application.supplicant != null && application.supplicant.length > 0)) {
	        	var msg = 'Result or supplicant is not provided';
	        	console.log(msg);
	        	AlertService.warning(msg);
	        	$state.go('modules.applications.detail',{ id: application.id });
	        	return;
	        }
	        

	        
	        vm.result = application.result[0];
	        
	        if(!vm.result.canCreateLoan) {
	        	var msg = 'Can not create Loan, wrong application result';
	        	console.log(msg);
	        	AlertService.warning(msg);
	        	$state.go('modules.applications.detail',{ id: application.id });
	        	return;
	        }
	        
	        vm.supplicant = application.supplicant;

	        vm.loan.reservedLoanNo = application.reservedLoanNo;
	        vm.loan.applId = application.id;
	        vm.loan.productName = application.productName;
	        vm.loan.applSum = application.applSum;
	        vm.loan.loanPayTot = application.loanPayTot;
	        vm.loan.tgrId = application.tgrId;
	        vm.loan.notes = application.notes;
	        
	        angular.forEach(vm.supplicant, function(supplicant){
	            switch(supplicant.supplicantType) {
		    		case "MAIN":
		    			vm.loan.mainMobPhone = supplicant.mobPhone;
		    			vm.loan.mainOtherPhone = supplicant.otherPhone;
		    			vm.loan.mainSex = supplicant.sex;
		    			vm.loan.mainFirstName = supplicant.firstName;
		    			vm.loan.mainLastName = supplicant.lastName;
		    			vm.loan.mainPersonCode = supplicant.personCode;
		    			vm.loan.mainPassNumber = supplicant.passNumber;
		    			vm.loan.mainPassExpire = supplicant.passExpire;
		    			vm.loan.mainCitizenship = supplicant.citizenship;
		    			vm.loan.mainBirthDate = supplicant.birthDate;
		    			vm.loan.mainEmail = supplicant.email;
		    			vm.loan.mainVoicePass = supplicant.voicePass;
		    			break;
		    		case "SURETY":
		    			vm.loan.grntMobPhone = supplicant.mobPhone;
		    			vm.loan.grntOtherPhone = supplicant.otherPhone;
		    			vm.loan.grntSex = supplicant.sex;
		    			vm.loan.grntFirstName = supplicant.firstName;
		    			vm.loan.grntLastName = supplicant.lastName;
		    			vm.loan.grntPersonCode = supplicant.personCode;
		    			vm.loan.grntPassNumber = supplicant.passNumber;
		    			vm.loan.grntPassExpire = supplicant.passExpire;
		    			vm.loan.grntCitizenship = supplicant.citizenship;
		    			vm.loan.grntBirthDate = supplicant.birthDate;
		    			vm.loan.grntEmail = supplicant.email;
		    			vm.loan.grntVoicePass = supplicant.voicePass;
		    			break;
	            };	        	
	        });
        
        vm.sign = function(){
        	Loan.create(vm.loan, function(result){
        		vm.hasReservedLoanNo = false;
        		vm.loan = result;
        		$state.go("modules.loans.current.condition", {loan_no: vm.loan.loanNo }, {reload: true});
        	});
        };
        
        vm.sexDataSource = new kendo.data.DataSource({
    		data: [
			{
    			key: "F",
    			value: $translate.instant("modules.application.female")
    		},{
    			key: "M",
    			value: $translate.instant("modules.application.male")
    		}]
    	});

    	vm.tariffData = new kendo.data.DataSource({
        	schema: {
			      model: {
			        id: "id",
			        fields: {
			        	"type": { type: "string" },
			        	"productType": { type: "string" },
			        	"personCode": { type: "string" },
			        	"name": { type: "string" },
			        	"priority": { type: "number" },
			        	"minAmount": { type: "number" },
			        	"maxAmount": { type: "number" },
			        	"defaultTrm": { type: "number" },
			        	"defaultAmount": { type: "number" }			    	
			        	}
				}
			},
			transport: {
				read: function(e){
				
					Calculator.query(
							{product : vm.loan.productName, person_code: vm.loan.mainPersonCode },
							function(response){
								e.success(response);
							},
							function(error){
								console.log('calculator tariffs error');
								e.error(error);
							}
					).$promise;
				}
			},
			sort: {
				field: "priority",
				dir: "desc"
			}
        });
        
        $scope.$watch(function() { return vm.loan.tgrId;}, function(newVal, oldVal) {
        	if(newVal !== oldVal) { 
        		calculate();
        		}
        });
        
        $scope.$watch(function() { return vm.loan.applSum;}, function(newVal, oldVal) {
        	if(newVal !== oldVal) { 
        		calculate();
        		}
        });
        
        function calculate(){
        	var calculator = {};
        	calculator.applAmount = vm.loan.applSum;
        	calculator.financedAmount = vm.loan.applSum;
        	calculator.personCode = vm.loan.mainPersonCode;
        	calculator.tariffId = vm.loan.tgrId;
        	Calculator.send(calculator, function(response){
        		if(response) vm.loan.loanPayTot = response.calcPayTot;
        	});
        }
        
    }
    
})();
