(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        	.state('modules.mvc', {
				url : '/mvc',
				parent : 'modules',
				abstract: true,
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO', 'ROLE_BUHALTERIS'],
	            },
				views : {
					'content@' : {
						templateUrl : 'app/modules/mvc/mvc.template.html',
						controller : 'MvcMenuController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }]
				}
			})
	       	.state('modules.mvc.operations', {
				url : '/operations/{d}',
				parent : 'modules.mvc',
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
	            },
				views : {
					'mvc-header': {
						templateUrl : 'app/modules/mvc/header/header.mvc.template.html',
						controller : 'HeaderMvcController',
						controllerAs : 'vm'
					},
					'mvc-body' : {
						templateUrl : 'app/modules/mvc/operations/operations.mvc.template.html',
						controller : 'OperationsMvcController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/mvc');
	                	$translatePartialLoader.addPart('modules/card.status');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
					previousState: ["$state", function ($state) {
	                    var currentStateData = {
	                        name: $state.current.name || 'modules.mvc',
	                        params: $state.params,
	                        url: $state.href($state.current.name, $state.params)
	                    };
	                    return currentStateData;
	                }],
	                entity: ['$stateParams', 'Mvc', function($stateParams, Mvc) {
	                    return Mvc.getOperations({d: $stateParams.d}).$promise;
	                }]
	                
				}
			})
	       	.state('modules.mvc.balances', {
				url : '/balances',
				parent : 'modules.mvc',
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
	            },
				views : {
					'mvc-header': {
						templateUrl : 'app/modules/mvc/header/header.mvc.template.html',
						controller : 'HeaderMvcController',
						controllerAs : 'vm'
					},
					'mvc-body' : {
						templateUrl : 'app/modules/mvc/balances/balances.mvc.template.html',
						controller : 'BalancesMvcController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/mvc');
	                	$translatePartialLoader.addPart('modules/card.status');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
					previousState: ["$state", function ($state) {
	                    var currentStateData = {
	                        name: $state.current.name || 'modules.mvc',
	                        params: $state.params,
	                        url: $state.href($state.current.name, $state.params)
	                    };
	                    return currentStateData;
	                }],
	                entity: ['Mvc', function(Mvc) {
	                	return Mvc.getBalances().$promise;
	                }]
				}
			})
	       	.state('modules.mvc.events', {
				url : '/events',
				parent : 'modules.mvc',
	            data: {
	            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
	            },
				views : {
					'mvc-header': {
						templateUrl : 'app/modules/mvc/header/header.mvc.template.html',
						controller : 'HeaderMvcController',
						controllerAs : 'vm'
					},
					'mvc-body' : {
						templateUrl : 'app/modules/mvc/events/events.mvc.template.html',
						controller : 'EventsMvcController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/mvc');
	                	$translatePartialLoader.addPart('modules/card.status');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
					previousState: ["$state", function ($state) {
	                    var currentStateData = {
	                        name: $state.current.name || 'modules.mvc',
	                        params: $state.params,
	                        url: $state.href($state.current.name, $state.params)
	                    };
	                    return currentStateData;
	                }],
	                entity: ['Mvc', function(Mvc) {
	                	return Mvc.getEvents().$promise;
	                }]
				}
			})
			;
    }
})();
