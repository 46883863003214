(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('HistoryClientController', HistoryClientController);

    HistoryClientController.$inject = ['$scope', '$state'];

    function HistoryClientController ($scope, $state) {
        var vm = this;
        console.log("HistoryClientController started");
        vm.cln = $state.params.clnId;
    }
    
})();
