(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('modules.applications', {
            parent: 'modules',
            url: '/applications',
            data: {
            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
            },
            views: {
                'content@': {
                    templateUrl: 'app/modules/application/applications.html',
                    controller: 'ApplicationsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('modules/application');
                    $translatePartialLoader.addPart('global');                    
                    return $translate.refresh();
                }]
            }
        })
        .state('modules.applications.detail', {
            parent: 'modules',
            url: '/applications/{id}',
            data: {
            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
            },
            views: {
                'content@': {
                    templateUrl: 'app/modules/application/application-dialog.html',
                    controller: 'ApplicationController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('modules/application');
                    $translatePartialLoader.addPart('global');                    
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Application', function($stateParams, Application) {
                    return Application.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'modules.applications',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('modules.applications.new', {
            parent: 'modules',
            url: '/applications/new/',
            params : { personCode: null, applSum: null, tgrId: null },
            data: {
            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
            },
            views: {
                'content@': {
                    templateUrl: 'app/modules/application/application-dialog.html',
                    controller: 'ApplicationController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('modules/application');
                    $translatePartialLoader.addPart('global');                    
                    return $translate.refresh();
                }],
                entity: function () {
                	
                    return {
                    	productName: "HERCUS_CARD",
                    	supplicant: [{
                    		supplicantType: "MAIN",
                    		otherPhone: "",
                    		mobPhone: "",
                    		contactPhone: ""
                    	},{
                    		supplicantType: "SURETY",
                    		otherPhone: "",
                    		mobPhone: ""
                    	}]
                    };
                },
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'modules.applications',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
               ;
    }

})();
