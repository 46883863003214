(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('SearchController', SearchController);

    SearchController.$inject = ['$scope', '$state', 'defaultPageSize', 'Search', '$stateParams', 'formHelper'];

    function SearchController ($scope, $state, defaultPageSize, Search, $stateParams, formHelper) {
        var vm = this;
		vm.noData = false ;
        console.log("search list controller");
        
        vm.searchString = $stateParams.look4.replace(","," ");
        formHelper.setFocus('search-page');
        vm.searchSubmit = function searchSubmit() {
        	console.log("start search ...");
        	var searchString = vm.searchString.trim().replace(" ",","); 
        	console.log(searchString);
        	$state.go('modules.search',{ look4: searchString });
        };
        
        vm.mainData = new kendo.data.DataSource({
        	schema: {
			      model: {
			        id: "docId",
			        fields: {
			        	"subject": { type: "string" },
			        	"docId": { type: "number" },
			        	"docType": { type: "number" },
				        "docCode": { type: "string" },
				        "docName": { type: "string"},
				        "docDate": { type: "date" },
				        "docSum": { type: "number" },
				        "notes": { type: "string" },
				        "indexed": { type: "date" }
			    	}
				}
			},
			transport: {
				read: function(e){
				
				Search.query(
						{look4 : $stateParams.look4},
						function(response){
			        		vm.noData = response.length > 0 ? false : true ;
							e.success(response);
						},
						function(error){
							console.log('search error');
							e.error(error);
						}
				).$promise;
				}
			},
			pageSize: defaultPageSize,
			sort: {
				field: "docDate",
				dir: "desc"
			}
        });
        


    }
    
})();
