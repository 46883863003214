(function() {
	'use strict';

	angular.module('sigmaApp').component('history', {
		templateUrl : "app/modules/history.template.html",
		controller : historyController,
		bindings : {
			doc: '=',
			type : '='
		}
	});

	historyController.$inject = [ '$scope', '$element', '$attrs', 'History', 'defaultPageSize', '$filter'];

	function historyController($scope, $element, $attrs, History, defaultPageSize, $filter) {
		var ctrl = this;
        ctrl.dateFormat = kendo.culture().calendar.patterns.G;
        ctrl.detail = false;
		ctrl.historyDataSource = new kendo.data.DataSource({
			schema : {
				model : {
					fields : {
						"eventId" : {
							type : "number"
						},
						"docId" : {
							type : "number"
						},
						"type" : {
							type : "string"
						},
						"subject" : {
							type : "string"
						},
						"desc" : {
							type : "string"
						},
						"comment" : {
							type : "string"
						},
						"createdOn" : {
							type : "date"
						},
						"createdBy" : {
							type : "string"
						}
					}
				}
			},
			transport : {
				read : function(e) {
					History.get({
						docId : ctrl.doc,
						query_tipas: ctrl.type
					}, function(response) {
						e.success(response);
					}, function(error) {
						console.log('history list error');
						e.error(error);
					});
				}
			},
			pageSize : defaultPageSize,
			serverPaging : false,
			serverFiltering : false,
			serverSorting : false,
			sort : {
				field : "createdOn",
				dir : "desc"
			}
		});

		ctrl.historyOptions = {
			scrollable : true,
			sortable : true,
			filterable : {
				extra : false,
				operators : {
					string : {
						contains : "contains",
					}
				}
			},
			pageable : {
				messages : {
					empty : "No data"
				}
			},
			toolbar : [ {
				template : "<span style='line-height:22px;' translate='modules.history.titles.list'></span>"
						+ "<button style='margin-right:1em;' class='pull-right btn btn-default btn-xs k-grid-excel'>"
						+ "<span class='fa fa-file-excel-o'></span>"
						+ "&nbsp;<span translate='modules.history.excel'>"
						+ "</span></button>"
			} ],
			excel : {
				fileName : "history.xlsx"
			},
			columns : [
					{
						field : "createdOn",
						headerTemplate : "<span translate='modules.history.table.date'>",
						width : "18ch",
						format : "{0:G}",
						filterable : {
							extra : true,
						},
					},
					{
						field : "type",
						headerTemplate : "<span translate='modules.history.table.type'>",
						width : "12ch",
					},
					{
						field : "createdBy",
						headerTemplate : "<span translate='modules.history.table.added'>",
						width : "12ch",
					},
					{
						field : "subject",
						headerTemplate : "<span translate='modules.history.table.subject'>",
						width : "25ch",
					},
					{
						headerTemplate : "<span translate='modules.history.table.actions'>",
						template : "<button kendo-tooltip k-position=\"'top'\" k-content=\"'{{ 'entity.action.view' | translate }}'\" class='btn btn-default k-button-icontext' ng-click='$ctrl.view(#:eventId#)'>"
								+ "<span class='glyphicon glyphicon glyphicon glyphicon-eye-open'></span>"
								+ "</button>",
						title : "&nbsp;",
						width : "10ch"
					} ]

		};
		
		ctrl.view = function(id){
			ctrl.detail = true;
			ctrl.currentEvent = $filter('filter')(ctrl.historyDataSource.data(), {eventId: id}, false)[0];
		};
		
		ctrl.close = function(){
			ctrl.detail = false;
			ctrl.currentEvent = {};
		};
	}

})();
