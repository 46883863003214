(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('ScheduleDetailLoanController', ScheduleDetailLoanController);

    ScheduleDetailLoanController.$inject = ['$scope', '$state', '$stateParams', 'schedules', '$filter'];

    function ScheduleDetailLoanController ($scope, $state, $stateParams, schedules, $filter) {
        var vm = this;
        vm.kendo = kendo;
        console.log("ScheduleDetailLoanController started");
        vm.editMode = false;
	    vm.currentSchedule = $filter('filter')(schedules, {nr: $stateParams.schId}, false)[0];
    }
    
})();
