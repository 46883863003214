(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('CardHolderRegistrationLoanController', CardHolderRegistrationLoanController);

    CardHolderRegistrationLoanController.$inject = ['$scope', '$state', 'Card', 'formHelper', 'defaultValue', '$translate', '$rootScope'];

    function CardHolderRegistrationLoanController ($scope, $state, Card, formHelper, defaultValue, $translate, $rootScope) {
        var vm = this;
        vm.kendo = kendo;
        console.log("Card Holder Registration Loan Controller started");
        vm.holder = defaultValue.cardholder[0];
        formHelper.setFocus('first-field');

        vm.addressOfDelivery = 1;
        changeAddress();
        
    	vm.dateFormat = kendo.culture().calendar.patterns.d;
    	vm.patternNationality = "[a-zA-Z]{2}|[a-zA-Z]{3}";
        
        
        vm.setDelivery = function() {
        	console.log(vm.addressOfDelivery);
        	changeAddress();
        };
        
        vm.cancel = function(){
        	console.log('cancel');
        	$state.go('modules.loans.condition');
        };
        	
        vm.save	= function(){
        	console.log('save');
        	$state.go('modules.loans.card');
        };
        
        vm.edit	= function(){
        	console.log('edit');
        	$state.go('modules.loans.condition');
        };
        
    	$rootScope.$on('$translateChangeSuccess', function () {
    		vm.dateFormat = kendo.culture().calendar.patterns.d;
        });
        
        vm.sexDataSource = new kendo.data.DataSource({
    		data: [
			{
    			key: "F",
    			value: $translate.instant("modules.loan.female")
    		},{
    			key: "M",
    			value: $translate.instant("modules.loan.male")
    		}]
    	});
        
        function changeAddress() {
        	if(vm.addressOfDelivery == 1) {
        		vm.holder.address1 = vm.holder.homeAddress1;
        		vm.holder.address2 = vm.holder.homeAddress2;
        		vm.holder.town = vm.holder.homeTown;
        		vm.holder.county = vm.holder.homeCounty;
        		vm.holder.postCode = vm.holder.homePostCode;
        		vm.holder.countryCode = vm.holder.homeCountryCode;
        	} else {
        		vm.holder.address1 = vm.holder.officeAddress1;
        		vm.holder.address2 = vm.holder.officeAddress2;
        		vm.holder.town = vm.holder.officeTown;
        		vm.holder.county = vm.holder.officeCounty;
        		vm.holder.postCode = vm.holder.officePostCode;
        		vm.holder.countryCode = vm.holder.officeCountryCode;
        	}
        }
    }
    
})();
