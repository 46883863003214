(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .factory('FileName', FileName);

    //FileName.$inject = [];
    
    function FileName(){
    	var service = {
    			normalize: normalize
    	}
    	
    	return service;
    	
    	function normalize(fileName) {
    			console.log(fileName);
	    		fileName = fileName.replace(/Ą/g, "A");  
				fileName = fileName.replace(/ą/g, "a");
		        fileName = fileName.replace(/Č/g, "C");
		        fileName = fileName.replace(/č/g, "c");
		        fileName = fileName.replace(/Ę/g, "E");
		        fileName = fileName.replace(/ę/g, "e");
		        fileName = fileName.replace(/Ė/g, "E");
		        fileName = fileName.replace(/ė/g, "e");
		        fileName = fileName.replace(/Į/g, "I");
		        fileName = fileName.replace(/į/g, "i");
		        fileName = fileName.replace(/Š/g, "S");
		        fileName = fileName.replace(/š/g, "s");
		        fileName = fileName.replace(/Ų/g, "U");
		        fileName = fileName.replace(/ų/g, "u");
		        fileName = fileName.replace(/Ū/g, "U");
		        fileName = fileName.replace(/ū/g, "u");
		        fileName = fileName.replace(/Ž/g, "Z");
		        fileName = fileName.replace(/ž/g, "z");
    			fileName = fileName.replace(/[^a-zA-Z0-9 ]/g, "");
    			console.log(fileName);
    			return fileName.replace(/\W/g, "_");
    	    };
    }
})();