(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('OperationsMvcController', OperationsMvcController);

    OperationsMvcController.$inject = ['$scope', '$rootScope', '$state', 'entity', 'defaultPageSize', '$translate'];

    function OperationsMvcController ($scope, $rootScope, $state, entity, defaultPageSize, $translate) {
        var vm = this;
        console.log("MVC Operations Controller started");
        vm.currentDate = new Date($state.params.d);
        vm.dateFormat = kendo.culture().calendar.patterns.d;
        
    	$rootScope.$on('$translateChangeSuccess', function () {
    		vm.dateFormat = kendo.culture().calendar.patterns.d;
        });
        
        $scope.$watch(function(){
    		return vm.currentDate;
    	}, function(newVal,oldVal) {
    		if (newVal != oldVal && newVal) {
    			$state.go('modules.mvc.operations',{d: newVal.toJSON().slice(0,10)}, {reload: true});
    		} else if (!newVal) {
    			newVal = new Date();
    			$state.go('modules.mvc.operations',{d: newVal.toJSON().slice(0,10)}, {reload: true});
    		}
    	});
        if(entity.hasOwnProperty('mvc')) { 
	        vm.mvcOperationsDataSource = new kendo.data.DataSource({
		        schema: {
		        	model: {
					        fields: {
					          "id": { type: "number" },
					          "itemId": { type: "number" },
					          "setlDate": { type: "date" },
					          "amount": { type: "number" },
					          "recType": { type: "string" },
					          "curr": { type: "string" },
					          "tokenRef": { type: "string" },
					          "loanRef": { type: "string" },
					          "des": { type: "string" }
					        }
					      },
			              parse: function(d){
			                	$.each(d, function (idx, elem) {
			                        elem.amount = elem.recType == 'UNLOAD' ? - elem.amount : elem.amount;
			                    });
			                	return d;
			              }
		  		},
				data: entity.mvc[0].operations,
		        aggregate: [ 
		        	{ field: "id", aggregate: "count"},
		            { field: "amount", aggregate: "sum"},
		        ],
		        pageSize: defaultPageSize,
		        serverPaging: false,
		        serverFiltering: false,
		        serverSorting: false,
		        sort: {
		              field: "id",
		              dir: "desc"
		        }
	        });
	        
	        vm.mvcOperationsOptions = {
	        		scrollable: true,
	                sortable: true,                
	                filterable: {
	                	extra: false,
	                	operators: {
	                        string: {
	                        	contains: "Contains",
	                        	eq: "Equal "
	                        },
	                        number: {
	                        	eq: "Equal "
	                        }
	                    }
	                },
	                pageable: {
	                	messages: {
	                	      empty: "No data"
	                	}
	                },
	                toolbar: [
	                          { 
			                    template: "<span class='col-sm-3' style='padding-left:0;line-height:30px;' translate='modules.mvc.titles.operations'></span>"
			                    		 + "<div class='form-group-sm'><div class='col-sm-5 col-md-4'><input class='form-control' kendo-date-picker k-format=\"'d'\"  placeholder='{{vm.dateFormat}}' k-ng-model='vm.currentDate'></div></div>"
			                    		 + "<button style='margin-right:1em;' class='pull-right btn btn-default btn-xs k-grid-excel'>"
			                    		 + "<span class='fa fa-file-excel-o'></span>"
			                             + "&nbsp;<span translate='modules.mvc.excel'>"
			                             + "</span></button>"
	                          }
	                ],
	                excel: {
	                	fileName: "mvc-operations.xlsx",
	                    allPages: true,
	                    filterable: true
	                },
	                excelExport: function(e) {
	                	e.workbook.sheets[0].columns.forEach(function(column){
	                		column.autoWidth = true;
	                	});
//	                	e.workbook.sheets[0].columns = [
//	                		{ width:100 },
//	                		{ width:300 },
//	                		{ width:400 },
//	                		// ...
//	                	];
	                    e.workbook.sheets[0].rows.forEach(function(row){
	                      if(row.type != 'data'){
	                      	row.cells.forEach(function(cell){
	                          cell.value = $translate.instant(cell.value);
	                        });
	                      }
	                	});
	                },
	                columns: [
							{ 
								field: "id", 
								title: "{{'modules.mvc.table.id'|translate}}",
								width: "12ch", 
								sortable: true,
								filterable: false,
						        aggregates: ["count"],
						        footerTemplate: "{{'modules.mvc.table.count'|translate}}: #=count#",
						        footerAttributes: {class: "text-center"}
							},{ 
								field: "setlDate", 
						        title: "{{'modules.mvc.table.date'|translate}}",
						        width: "12ch",
						        format: "{0:d}",
						        filterable: false,
						        sortable: false
							},{ 
								field: "recType", 
						        title: "{{'modules.mvc.table.recType'|translate}}",
						        width: "12ch",
						        sortable: true
						    },{ 
								field: "amount",
								title: "{{'modules.mvc.table.sum'|translate}}",
							    attributes: { class: "text-right" },
							    sortable: true,
							    format: "{0:n2}",
						        aggregates: ["sum"], 
						        footerTemplate: "#= kendo.toString(sum, 'n2') #",
						        footerAttributes: {class: "text-right"},
							    width: "15ch"
							},{ 
								field: "tokenRef",
								title: "{{'modules.mvc.table.token'|translate}}",
							    sortable: true,
							    width: "12ch"
							},{ 
								field: "loanRef",
								title: "{{'modules.mvc.table.reference'|translate}}",
							    sortable: true,
							    width: "14ch"
							},{
				                field: "des",
				                title: "{{'modules.mvc.table.description'|translate}}",
				                sortable: true
				            }  
					]	
	                            	
	        	};
	 
	        };
    	}
    
})();
