(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('ClientMenuController', ClientMenuController);

    ClientMenuController.$inject = ['$scope', '$state', '$stateParams', 'eehNavigation'];

    function ClientMenuController ($scope, $state, $stateParams, eehNavigation) {
        var vm = this;
        console.log("ClientMenuController ...");
        
        eehNavigation
		        .menuItem('sidenavClient.view', {
		          text: 'modules.client.menu.view',
		          iconClass: 'fa-list-alt',
		          state: 'modules.clients.view',
		          weight: -10
		        })
		        .menuItem('sidenavClient.addresses', {
		          text: 'modules.client.menu.addresses',
		          iconClass: 'fa-random',
		          state: 'modules.clients.addresses',
		          weight: -10,
		        })
		        .menuItem('sidenavClient.phones', {
		          text: 'modules.client.menu.phones',
		          iconClass: 'fa-phone',
		          state: 'modules.clients.phones',
		          weight: -10,
		        })
		        .menuItem('sidenavClient.emails', {
		          text: 'modules.client.menu.emails',
		          iconClass: 'fa-envelope-o',
		          state: 'modules.clients.emails',
		          weight: -10,
		        })
		        .menuItem('sidenavClient.history', {
		          text: 'modules.client.menu.history',
		          iconClass: 'fa-history',
		          state: 'modules.clients.history',
		          weight: -10,
		        })
		        .iconBaseClass('fa');
    }
    
})();
