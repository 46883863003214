(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('DetailClientController', DetailClientController);

    DetailClientController.$inject = ['$scope', '$state', 'currentClient', '$translate', '$rootScope', 'formHelper', 'detailMode', 'AlertService', 'Client'];

    function DetailClientController ($scope, $state, currentClient, $translate, $rootScope, formHelper, detailMode, AlertService, Client) {
        var vm = this;
        vm.kendo = kendo;
        console.log("Detail Client Controller started");
        vm.currentClient = angular.copy(currentClient[0]);
        delete vm.currentClient.address;
        delete vm.currentClient.email;
        delete vm.currentClient.phone;
        vm.clnId = $state.params.clnId;

    	formHelper.setFocus('first-field');
    	vm.isViewOnly = !detailMode.edit;
    	
    	vm.dateFormat = kendo.culture().calendar.patterns.d;
    	vm.dateMask = vm.dateFormat.replace("dd","00").replace("yyyy","0000").replace("MM","00");
    	vm.patternNationality = "[a-zA-Z]{2}|[a-zA-Z]{3}";
    	
    	$rootScope.$on('$translateChangeSuccess', function () {
    		vm.dateFormat = kendo.culture().calendar.patterns.d;
        });
        
    	vm.save = function() {
			
    		console.log("saving changes in Detail client ...");
    		Client.update(
					{clnId: vm.clnId},
					vm.currentClient,
					function(responce){
						vm.currentClient = responce;
						AlertService.success("Client's data updated successfully");
						$state.go('modules.clients.view', {clnId: vm.clnId}, {reload: true});
					});
			
		};
    	
    	
    	vm.sexDataSource = new kendo.data.DataSource({
    		data: [
			{
    			key: "F",
    			value: $translate.instant("modules.client.female")
    		},{
    			key: "M",
    			value: $translate.instant("modules.client.male")
    		}]
    	});
        
        vm.docType = new kendo.data.DataSource({
    		data: [
			{
    			key: "1",
    			value: $translate.instant("modules.client.social_insurance")
    		},{
    			key: "2",
    			value: $translate.instant("modules.client.pensioner")
    		},{
    			key: "3",
    			value: $translate.instant("modules.client.disablement")
    		},{
    			key: "4",
    			value: $translate.instant("modules.client.other")
    		}]
    	});
        
        
    }
})();
