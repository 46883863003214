(function() {
    'use strict';
    angular
        .module('sigmaApp')
        .factory('CardStatus', CardStatus)
        ;

    CardStatus.$inject = [];

    function CardStatus () {
        console.log("CardStatus started");
        var getStyle = function(item){
        	var resp;
        	switch(item) {
        		case '00':
        			resp = 'success';
        			break;
        		case '03':
        			resp = 'warning';
        			break;
        		default:
        			resp = 'danger';
        	}
        	return resp;
        };
        return {
        	getStyle: getStyle
        };
    }
})();
