(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('CalculatorController', CalculatorController);

    CalculatorController.$inject = ['$scope', '$state', 'Calculator', 'formHelper', '$element', '$window', '$timeout'];

    function CalculatorController ($scope, $state, Calculator, formHelper, $element, $window, $timeout) {
        var vm = this;
        vm.kendo = kendo;
        vm.noTariffs = false;
        vm.product = "HERCUS_CARD";
        vm.tariff = {};
        vm.calculator = {};
    //    console.log("calculator controller");
        vm.personCode = '';
        vm.applAmount = 0;
        vm.financedAmount = 0;
        
        vm.calculate = calculate; 
        	
        vm.loadTariffsAndCalculate = function(){
        	if(vm.tariff.personCode != vm.personCode) {
        		vm.tariff.personCode = vm.personCode;
        		loadTariffs(false);
        	} 
        	calculate();
        }; 

        vm.setTariff = function(id, min, max){
        	vm.calculator.tariffId = id;
        	vm.minSum = min;
        	vm.maxSum = max;
        //	console.log(vm.minSum);
        	calculate();
        };
        vm.mainData = new kendo.data.DataSource({
        	schema: {
			      model: {
			        id: "id",
			        fields: {
			        	"type": { type: "string" },
			        	"productType": { type: "string" },
			        	"personCode": { type: "string" },
			        	"name": { type: "string" },
			        	"priority": { type: "number" },
			        	"minAmount": { type: "number" },
			        	"maxAmount": { type: "number" },
			        	"defaultTrm": { type: "number" },
			        	"defaultAmount": { type: "number" }			    	}
				}
			},
			transport: {
				read: function(e){
				
					Calculator.query(
							{product : vm.product, person_code: vm.tariff.personCode },
							function(response){
				        		vm.noTariffs = response.length > 0 ? false : true ;
								e.success(response);
							},
							function(error){
								console.log('calculator tariffs error');
								e.error(error);
							}
					).$promise;
				}
			},
			sort: {
				field: "priority",
				dir: "desc"
			}
        });
        
        loadTariffs(true);
        
        function loadTariffs(toCalculate){
        	vm.mainData.read()
	        	.then(function(){
	        		var data = vm.mainData.data();
	        		data.sort(function(a, b){
	        			  a = parseInt(a['priority']);
	        			  b = parseInt(b['priority']);
	        			  return b - a;
	        			});
	        		var item = data[0];
		        	vm.applAmount = vm.applAmount ? vm.applAmount : item.defaultAmount; 
		        	vm.financedAmount = vm.financedAmount ? vm.financedAmount : item.defaultAmount;
		        	vm.calculator.tariffId = item.id;
		        	vm.minSum = item.minAmount;
		        	vm.maxSum = item.maxAmount;
		        	resizeWrap(getWrapHeight());
		        	if(toCalculate) calculate();
	        	});
        }
        
        function resizeWrap(ht){
        	vm.tariffsWrap = ht;
        }
        
        $scope.$watch(
	        function(){
	        	return $element.find('#calc').prop('offsetWidth');
	        },
	        function(){
	        	resizeWrap(getWrapHeight());
	        }
        );
        
        $scope.$watch(
    	    function(){
    	    	return vm.applAmount;
    	    },
    	    function(newValue,oldValue){
    	    	if(oldValue != newValue) vm.financedAmount = newValue;
    	    }
        );
        
        function getWrapHeight() {
        	var ht = $element.find('#calc').prop('offsetHeight')-$element.find('#wrap').prop('offsetTop');
        	return $window.innerWidth < 768 ? 'auto' : ht + 'px';
        }
        
        function calculate(){
        	var calculator = vm.calculator;
        	calculator.applAmount = vm.applAmount;
        	calculator.financedAmount = vm.financedAmount;
        	calculator.personCode = vm.personCode;
        	vm.calculator = {};
        	Calculator.send(calculator, function(response){
        		if(response) vm.calculator = response;
        	});
        }
        
        vm.infoLapa = function(){
        	console.log('Printing InfoLapa');
        	Calculator.printInfoLapa(vm.calculator, function(response){
        		var win = $window.open('', '_blank');
        		console.log(response);
        		win.document.write(response);
        	});
        };

    }
    
})();
