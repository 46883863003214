(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('LoanMenuController', LoanMenuController);

    LoanMenuController.$inject = ['$scope', '$state', '$stateParams', 'eehNavigation', 'menuWithHeader', 'Rights'];

    function LoanMenuController ($scope, $state, $stateParams, eehNavigation, menuWithHeader, Rights) {
        var vm = this;
        console.log("LoanMenuController ...");
        
        
        Rights.init(menuWithHeader[0].rights);
        
        eehNavigation
			    .menuItem('sidenav.loan', {
		          text: 'modules.loan.menu.loan',
		          iconClass: 'fa-list-alt',
		          isCollapsed: false,
		          weight: -10
		        })
		        .menuItem('sidenav.loan.conditions', {
		          text: 'modules.loan.menu.conditions',
		          iconClass: 'fa-check-circle-o',
		          state: 'modules.loans.current.condition',
		          weight: -10,
		          isVisible: Rights.isAllowed('ALLOW_LOAN_CONDITION_VIEW')		          
		        })
		        .menuItem('sidenav.loan.operations', {
		          text: 'modules.loan.menu.operations',
		          iconClass: 'fa-random',
		          state: 'modules.loans.current.operations',
		          weight: -10,
		          isVisible: Rights.isAllowed('ALLOW_LOAN_OPERATIONS_VIEW')
		        })
		        .menuItem('sidenav.loan.payments', {
		          text: 'modules.loan.menu.payments',
		          iconClass: 'fa-money',
		          state: 'modules.loans.current.payments',
		          weight: -10,
		          isVisible: Rights.isAllowed('ALLOW_LOAN_PAYMENTS_VIEW')
		        })
		        .menuItem('sidenav.loan.schedules', {
		          text: 'modules.loan.menu.schedules',
		          iconClass: 'fa-calendar',
		          state: 'modules.loans.current.schedules',
		          weight: -8,
		          isVisible: Rights.isAllowed('ALLOW_LOAN_SCHEDULES_VIEW')
		        })
		        .menuItem('sidenav.loan.interests', {
		          text: 'modules.loan.menu.interestRates',
		          iconClass: 'fa-percent',
		          state: 'modules.loans.current.interests',
		          weight: -8,
		          isVisible: Rights.isAnyAllowed('ALLOW_LOAN_INTERESTS_VIEW, ALLOW_LOAN_INTERESTS_EDIT, ALLOW_LOAN_INTERESTS_DELETE')
		        })
		        .menuItem('sidenav.loan.history', {
		          text: 'modules.loan.menu.history',
		          iconClass: 'fa-history',
		          state: 'modules.loans.current.history',
		          weight: -8,
		          isVisible: Rights.isAllowed('ALLOW_LOAN_HISTORY_VIEW')
		        })
		        .menuItem('sidenav.cards', {
		          text: 'modules.loan.menu.cards',
		          iconClass: 'fa-credit-card',
		          isCollapsed: false,
		          weight: -6,
		        })
		        .menuItem('sidenav.cards.order', {
			      text: 'modules.loan.menu.orderCard',
			      iconClass: 'fa-handshake-o',
			      weight: -6,
			      state: 'modules.loans.current.order-card',
			      isVisible: Rights.isAllowed('ALLOW_LOAN_CARDHOLDER_CREATE')
			    })
		        .menuItem('sidenav.cards.holder', {
		          text: 'modules.loan.menu.cardHolder',
		          iconClass: 'fa-user-circle-o',
		          weight: -6,
		          state: 'modules.loans.current.card',
		          isVisible: Rights.isAllowed('ALLOW_LOAN_CARDHOLDER_VIEW')
		        })
		        .menuItem('sidenav.cards.list', {
		          text: 'modules.loan.menu.cardsList',
		          iconClass: 'fa-credit-card',
		          weight: -6,
		          state: 'modules.loans.current.card.list',
		          isVisible: Rights.isAllowed('ALLOW_LOAN_CARDS_VIEW')
		        })
		        .iconBaseClass('fa');
    }
    
})();
