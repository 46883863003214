(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('InterestListLoanController', InterestListLoanController);

    InterestListLoanController.$inject = ['$scope', '$state', '$stateParams', 'Loan', 'defaultPageSize', 'interests'];

    function InterestListLoanController ($scope, $state, $stateParams, Loan, defaultPageSize, interests) {
        var vm = this;
        vm.kendo = kendo;
        console.log("InterestListLoanController started");
      	
        vm.loanInterestsDataSource = new kendo.data.DataSource({
        	data: interests,
    		schema: {
			      model: {
			    	id: "id",
			        fields: {
			        	id: { editable: false, nullable: true},
	                    loan: {editable: false, nullable: false, defaultValue: $stateParams.id },
	                    dateFrom: {type: "date", nullable: false, defaultValue: new Date()},
	                    rateValue: {type: "number", nullable: false,  }, //defaultValue: <%= replace(string(ls_con.lcn_int_rate * 100, ">>9.99"), ",", ".") %>
	                    compCode: {nullable: false, defaultValue: "INT"},
	                    compName: {editable: false, nullable: true},
	                    addDate: {type: "date", editable: false, nullable: true},
	                    edtDate: {type: "date", editable: false, nullable: true},
	                    addUser: {editable: false, nullable: true},
	                    edtUser: {editable: false, nullable: true},
			        }
			      }
    		},
            pageSize: defaultPageSize,
            serverPaging: false,
            serverFiltering: false,
            serverSorting: false,
            sort: {
                field: "dateFrom",
                dir: "desc"
            }
        });
        
        vm.loanInterestsOptions = {
        		scrollable: true,
                sortable: true,                
                filterable: false,
                pageable: {
                	refresh: true,
                	messages: {
                	      empty: "No data"
                	    }
                },
                toolbar: [
                          { 
  		                    template: "<span style='line-height:22px;' translate='modules.loan.titles.interestRates'></span>"
  		                    		 + "<button style='margin-right:1em;' class='pull-right btn btn-default btn-xs k-grid-excel'>"
  		                    		 + "<span class='fa fa-file-excel-o'></span>"
  		                             + "&nbsp;<span translate='modules.loan.excel'>"
  		                             + "</span></button>"
  		                             + "<button style='margin-right:1em;' class='pull-right btn btn-default btn-xs' ui-sref='modules.loans.current.interests.create'>"
		                    		 + "<span class='glyphicon glyphicon-plus'></span>"
		                             + "&nbsp;<span translate='modules.loan.add'>"
		                             + "</span></button>"
                            }
                ],
                excel: {
                	fileName: "loan-schedules.xlsx"
                },
                columns: [
                          { 
                        	  field: "dateFrom", 
 	                          headerTemplate: "<span translate='modules.loan.table.from'>",
                        	  format: "{0:d}", 
                        	  width: "18ch"
                          },{ 
                        	  field: "rateValue", 
                        	  headerTemplate: "<span translate='modules.loan.table.rateValue'>", 
                        	  width: "15ch", 
                        	  format: "{0:n2}", 
                        	  attributes: { "class": "text-right" }
                          },{ 
                        	  field: "reason", 
                              headerTemplate: "<span translate='modules.loan.table.reason'>"
                          },{ 
                        	  field: "edtDate", 
                              headerTemplate: "<span translate='modules.loan.table.edtDate'>",
                        	  format: "{0:g}", 
                        	  width: "17ch"
                          },{
                          	headerTemplate: "<span translate='modules.loan.actions'>",
                        	template: "<button kendo-tooltip k-position=\"'top'\" k-content=\"'{{ 'entity.action.view' | translate }}'\" class='btn btn-default k-button-icontext' ui-sref='modules.loans.current.interests.view({rateId: #:id#})'>"
                                + "<span class='glyphicon glyphicon glyphicon glyphicon-eye-open'></span>"
                                + "</button>",
		                        title: "&nbsp;", 
		                        width: "10ch" 
                          }
                ]
        };
      
       
    }
    
})();
