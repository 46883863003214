(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('DeleteLoanController', DeleteLoanController);

    DeleteLoanController.$inject = ['$uibModalInstance', '$state', 'Loan', 'AlertService'];

    function DeleteLoanController ($uibModalInstance, $state, Loan, AlertService) {
        var vm = this;

        if($state.params.loan_no == null ) {
        	$uibModalInstance.dismiss('cancel');
        	AlertService.error("Loan don't get for deleting, or Loan is wrong");
        	return;
        }
 
        
        vm.loanNo = $state.params.loan_no; 
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (loanNo) {
        	AlertService.success("Loan deleted successfully (No. " + loanNo + ")");
        	$uibModalInstance.close(true);
            Loan.delete({loan_no: loanNo},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
