(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .factory('formHelper', FormHelper);

    FormHelper.$inject = ['$window', "$timeout"];
    
    function FormHelper($window, $timeout){
    	var service = {
    			setFocus: setFocus
    	}
    	
    	return service;
    	
    	function setFocus(id) {
    	      // timeout makes sure that it is invoked after any other event has been triggered.
    	      // e.g. click events that need to run before the focus or
    	      // inputs elements that are in a disabled state but are enabled when those events
    	      // are triggered.
    	      $timeout(function() {
    	        var element = $window.document.getElementById(id);
    	        if(element)
    	          element.focus();
    	      });
    	    };
    }
})();